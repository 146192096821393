import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccRemarksInputInterface } from './acc-remarks-input-service/acc-remarks-input.service';

@Component({
  selector: 'app-acc-remarks-input',
  templateUrl: './acc-remarks-input.component.html',
  styleUrls: ['./acc-remarks-input.component.scss']
})
export class AccRemarksInputComponent {

  public remarksValue: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AccRemarksInputInterface,
    private _dialogRef: MatDialogRef<AccRemarksInputComponent>,
  ) { }

  public onSubmit() {
    this.data.onSubmit(this.remarksValue, this._dialogRef);
  }

  public onCancel() {
    if (this.data.onCancel) this.data.onCancel();
    this._dialogRef.close();
  }

}
