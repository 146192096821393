import { Injectable } from '@angular/core';
import { HttpApiService } from '../http-api/http-api.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, finalize, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { CurrencyConvertionInterface, DepartmentInterface, DetachmentInterface } from '../../interfaces/global.interface';
import { WaitHandler } from '../../classes/wait-handler.class';
import { PromptMessage } from '../../classes/promp-message.class';

@Injectable({
  providedIn: 'root'
})
export class GlobalApiService {

  constructor(
    private _api: HttpApiService,
  ) { }

  /**
   * `1st` - Get the upload URL to upload a file
   * @param file The actual file to be uploaded
   * @param filename The actual filename to be uploaded
   * @param directory The directory where the file is saved. `"Receipts"` for Accounting. `"employeeImage"` for HRIS employee image.
   * @returns 
   */
  public getUploadFileUrl(file: File, filename: string, directory: string) {
    return this._api.hris.sendPostRequest<HttpResponse<{ presignedURL: string }>>('/get/presigned/upload', {
      fileName: `${filename}`,
      fileType: file.type,
      fileBaseDir: directory,
    },
      new HttpHeaders({ "content-type": file.type }),
    );
  }

  /**
   * `2nd` - Upload the actual file to the server.
   * @param presignedURL The URL fetched when requesting for upload URL in 1st step.
   * @param file The actual file to be uploaded.
   * @returns 
   */
  public putFileInServer(presignedURL: string, file: File) {
    return this._api.raw.sendPutRequest<HttpResponse<any>>(presignedURL, file, new HttpHeaders({ "content-type": file.type }),);
  }

  /**
   * `1st/3rd` Gets the file URL uploaded in the server.
   * @param filename File name uploaded on the server.
   * @param directory The directory where the file is saved. `"Receipts"` for Accounting. `"employeeImage"` for HRIS employee image.
   * @returns 
   */
  public getFileUrl(filename: string, directory: string) {
    return this._api.hris.sendGetRequest<HttpResponse<{ result: string, url: string }>>(`/get_file_presigned_url?fileName=${filename}&fileBaseDir=${directory}`);
  }

  /**
   * `Combined` Upload a file to the server
   * @param waitHandler Your WaitHandler instance.
   * @param filename File name uploaded on the server.
   * @param file The actual file to be uploaded.
   * @param directory The directory where the file is saved. `"Receipts"` for Accounting. `"employeeImage"` for HRIS employee image.
   * @returns 
   */
  public uploadFileWrapped(waitHandler: WaitHandler, fileName: string, file: File, directory: string) {
    return of(null).pipe(
      tap(() => waitHandler.newRequest()),
      switchMap(() => this.getUploadFileUrl(file, fileName, directory).pipe(
        finalize(() => waitHandler.finishRequest()),
        map(values => values.body?.presignedURL),
        switchMap(presignedURL => of(presignedURL).pipe(
          tap(() => waitHandler.newRequest()),
          switchMap(presignedURL => this.putFileInServer(presignedURL ?? '', file).pipe(
            finalize(() => waitHandler.finishRequest()),
          ))
        ))
      ))
    );
  }

  public getDepartmentList() {
    return this._api.hris.sendGetRequest<HttpResponse<any>>('/departments').pipe(
      map(values => {
        return values?.body.result as DepartmentInterface[];
      }),
    )
  }

  public getDetachmentList() {
    return this._api.hris.sendGetRequest<HttpResponse<DetachmentInterface[]>>('/detachments-list').pipe(
      map(values => {
        return values?.body;
      }),
    )
  }

  public convertPhpToUsd(PHPvalue: string | number): Observable<CurrencyConvertionInterface> {
    return this._api.raw.sendGetRequest<HttpResponse<CurrencyConvertionInterface>>(`https://api.frankfurter.app/latest?amount=${PHPvalue}&from=PHP&to=USD`).pipe(
      map(values => values?.body),
      map((values: any) => ({ ...values, Passedamount: values.amount } as CurrencyConvertionInterface)),
      catchError(() => of({ Passedamount: 0, base: "", date: "", rates: { USD: 0, } })),
    );
  }

}
