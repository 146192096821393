import { Injectable } from '@angular/core';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';
import { SideNavBarInterface } from 'src/app/accountancy/utilities/interfaces/global.interface';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private routes = Routes;

  public sideMenus: SideNavBarInterface[] = [

    {
      routeLink: this.routes.dashboard,
      icon: 'fal fa-home',
      label: 'Dashboard',
      access: Helper.users.accessList.Dashboard,
    },
    {
      routeLink: this.routes.tabName.disburesement,
      icon: 'fal fa-cash-register',
      label: 'Disbursement',
      access: null,
      items: [
        {
          routeLink: this.routes.disbursementList,
          label: 'Disbursement List',
          access: null,
        },
        {
          routeLink: this.routes.cashAdvance,
          label: 'Cash Advance',
          access: null,
        },
        {
          routeLink: this.routes.liquidation,
          label: 'Liquidation',
          access: Helper.users.accessList['Disbursement>Liquidation'],
        },
        {
          routeLink: this.routes.reimbursement,
          label: 'Reimbursement',
          access: Helper.users.accessList['Disbursement>Reimbursement']
        },
      ]
    },
    // {
    //   routeLink: this.routes.tabName.prs,
    //   icon: 'fal fa-sack-dollar',
    //   label: 'PRS',
    //   access: Helper.users.accessList.PRS,
    // },
    {
      routeLink: this.routes.accountPayable,
      icon: 'fal fa-money-check-alt',
      label: 'Accounts Payable',
      access: null,
    },
    {
      routeLink: this.routes.accountReceivable,
      icon: 'fal fa-envelope-open-dollar',
      label: 'Accounts Receivable',
      access: null,
    },
    {
      routeLink: this.routes.manageClients,
      icon: 'fal fa-map-marked-alt',
      label: 'Manage Clients',
      access: null
    },
    {
      routeLink: this.routes.generalLedger,
      icon: 'fal fa-funnel-dollar',
      label: 'General Ledger',
      access: null
    },
    {
      routeLink: undefined,
      icon: 'fal fa-money-check-edit',
      label: 'Tax Accounting',
      access: null,
    },
    {
      routeLink: undefined,
      icon: 'fal fa-file-invoice-dollar',
      label: 'Financial Reporting',
      access: null,
    },
    {
      routeLink: undefined,
      icon: 'fal fa-comments-alt-dollar',
      label: 'Reconciliation',
      access: null,
    },
    {
      routeLink: undefined,
      icon: 'fal fa-hands-usd',
      label: 'Audit & Compliance',
      access: null,
    },
    {
      routeLink: undefined,
      icon: 'fal fa-wallet',
      label: 'Budgeting & Forecasting',
      access: null,
    },
    {
      routeLink: 'user',
      icon: 'fal fa-gear',
      label: 'Administration',
      access: Helper.users.accessList['Account Management'],
      items: [
        {
          routeLink: this.routes.userList,
          label: 'User List',
          access: Helper.users.accessList['Account Management>User list'],
        },
        {
          routeLink: this.routes.userAccess,
          label: 'User Access',
          access: Helper.users.accessList['Account Management>User Access'],
        },
        {
          routeLink: this.routes.manageApprover,
          label: 'Manage Approvers',
          access: Helper.users.accessList['Disbursement>Manage Approver'],
        },
        {
          routeLink: this.routes.manageChartOfAccounts,
          label: 'Manage Chart of Accounts',
          access: null,
        },
        {
          routeLink: this.routes.userAudits,
          label: 'Audit Logs',
          access: Helper.users.accessList['Account Management>Audit logs'],
        }
      ]
    },
  ]

  constructor() { }
}

