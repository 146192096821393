<div class="card md:min-w-[500px]">
  <div class="card-header"> {{data.title}} </div>
  <div class="card-body">
    <mat-dialog-content>
      <p *ngFor="let msg of getMessages" class="{{data.messageType}}">{{msg}}</p>
    </mat-dialog-content>
  </div>
  <div class="card-footer">
    <div class="flex flex-row-reverse justify-between items-center mb-4">
      <div class="form-submit">
        <button (click)="onAnswer(true)" class="custom-button custom-button-primary-filled">
          {{data.confirmText||'Confirm'}}
        </button>
        <button (click)="onAnswer(false)" class="custom-button custom-button-primary">
          {{data.cancelText||'Cancel'}}
        </button>
      </div>
      <button *ngIf="!data.disableCloseButton" (click)="onClose()" class="custom-button">Cancel</button>
    </div>
  </div>
</div>