import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, finalize, forkJoin, map, mergeMap, Observable, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { AccSearchTipComponent } from 'src/app/accountancy/custom-components/acc-search-tip/acc-search-tip.component';
import { Pagination } from 'src/app/accountancy/utilities/classes/pagination.class';
import { AccMessageDialogComponent, AccMessageDialogInterface } from 'src/app/accountancy/custom-components/acc-message-dialog/acc-message-dialog.component';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ManageApproverService } from '../../../manage-approver/manage-approver-service/manage-approver.service';
import { ApiGetResponse } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { ReimbursementService } from '../../reimbursement-service/reimbursement.service';
import { ReimbursementViewDetailsComponent } from '../tab-reimbursement-all-request/reimbursement-view-details/reimbursement-view-details.component';
import { ReimbursementModifiedInterface } from 'src/app/accountancy/utilities/interfaces/reimbursement.interface';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';

@Component({
  selector: 'app-tab-reimbursement-status',
  templateUrl: './tab-reimbursement-status.component.html',
  styleUrls: ['./tab-reimbursement-status.component.scss']
})
export class TabReimbursementStatusComponent {
  public checkAll = false;

  public readonly pagination = new Pagination();
  public readonly reimbursementList$ = new BehaviorSubject<ReimbursementModifiedInterface[]>([]);

  private readonly _subsGetCaList = this._getSubsGetReimbursementList();

  constructor(
    public loader: LoaderService,

    private _manageApprovalS: ManageApproverService,
    private _loginS: LoginService,
    private _reimbursementS: ReimbursementService,
    private _matDialog: MatDialog,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetCaList.unsubscribe();
  }

  public onDisapprove(reimbursement: ReimbursementModifiedInterface) {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._reimbursementS.updateStatus(reimbursement.CntrlNumber ?? '', 'Disapproved').pipe(
        finalize(() => {
          this.pagination.reloader$.next();
          this.loader.finish();
          subs.unsubscribe();
        })
      ))
    ).subscribe();
  }

  public onApprove(reimbursement: ReimbursementModifiedInterface) {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._reimbursementS.updateStatus(reimbursement.CntrlNumber ?? '', reimbursement.NextStatus ?? 'No Status').pipe(
        finalize(() => {
          this.pagination.reloader$.next();
          this.loader.finish();
          subs.unsubscribe();
        })
      )),
    ).subscribe();
  }

  public dialogs = {
    openSearch: () => this._matDialog.open(AccSearchTipComponent),
    openViewMore: (reimbursement: ReimbursementModifiedInterface) => this._matDialog.open(ReimbursementViewDetailsComponent, { data: reimbursement }),
    openPrint: async () => {
      const selectedReimbursement = this.reimbursementList$.getValue().filter(v => v.checked);

      // MessageBox Value when there is a selected Reimbursement
      const messageBoxData: AccMessageDialogInterface = {
        title: 'Generating PDF',
        message: 'Please wait. Your PDF is being generated.',
        disableClodeButton: true,
        callBackDelay: 3000,
        callBack: (dialog) => dialog.close(),
      };

      // MessageBox value when there is no selected Reimbursement
      const messageBoxDataFailed: AccMessageDialogInterface = {
        title: 'Information',
        message: 'Please select at least 1 item to be generated.',
      };

      if (selectedReimbursement.length) {

        this.loader.request();

        // Generate PDF of selected Reimbursement
        await this._reimbursementS.printSelectedRItems(selectedReimbursement);

        // End request
        this.loader.finish();

        // Change all the checkbox to unchecked
        this._changeCheckForCAList(false);

        this._matDialog.open(AccMessageDialogComponent, { data: messageBoxData, disableClose: true });
      } else {

        // Open dialog with error message
        this._matDialog.open(AccMessageDialogComponent, { data: messageBoxDataFailed, disableClose: true });
      }
    }
  }

  public toggleCheckAll(e: Event) {
    const checked = (e.target as HTMLInputElement).checked;
    this._changeCheckForCAList(checked);
  }

  public transformStatus(status: string) {
    if (status == "Done") return 'success';
    if (status == "Disapproved") return 'danger';
    return 'warning';
  }

  private _changeCheckForCAList(newValue: boolean) {
    this.checkAll = newValue;
    this.reimbursementList$.getValue().forEach(v => v.checked = newValue);
  }

  private _getSubsGetReimbursementList() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query)),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => this.loader.request()),
      switchMap(([query, limit]) => this._reimbursementS.getReimbursementRequests(this.pagination.start, limit, query, null, this._loginS.userData?.ID).pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body),
        mergeMap(values => {
          if (!values) return of({ data: [], total_rows: 0 } as ApiGetResponse<ReimbursementModifiedInterface>);

          const modifiedCaList = values?.data.map(v => of(v).pipe(
            switchMap(values => this._getNextStatus(values.Status).pipe(
              map(nextStatus => ({ ...values, NextStatus: nextStatus } as ReimbursementModifiedInterface))
            ))
          ));

          if (!modifiedCaList.length) return of(values);

          return forkJoin(modifiedCaList).pipe(
            map(newList => ({ data: newList, total_rows: values.total_rows } as ApiGetResponse<ReimbursementModifiedInterface>))
          );
        }),
        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.reimbursementList$.next(values?.data.map(v => ({ ...v, checked: false } as ReimbursementModifiedInterface)) ?? []);
        }),
      ))
    ).subscribe();
  }

  private _getNextStatus(currentStatus: string): Observable<string | undefined> {
    if (currentStatus == "Done" || currentStatus == "Disapproved") return of(undefined);
    return of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._manageApprovalS.getApprovalLevels().pipe(
        finalize(() => this.loader.finish()),
        map(values => values?.data),
        map(values => {
          if (!values) return 'No Status';

          const currentIndex = values?.findIndex(v => v.Status == currentStatus);
          if (currentIndex < 0) return values[0].Status;

          return (currentIndex + 1 == values.length) ? 'Done' : values[currentIndex + 1].Status;
        })
      ))
    )
  }

}
