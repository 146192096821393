export class Routes {

  public static readonly login = '/auth/login';
  public static readonly forgotPassword = '/auth/forgot-password';

  public static readonly home = '/home';
  public static readonly dashboard = '/home/dashboard';

  public static readonly disbursement = '/home/disbursement';
  public static readonly cashAdvance = '/home/disbursement/cash-advance';
  public static readonly reimbursement = '/home/disbursement/reimbursement';
  public static readonly liquidation = '/home/disbursement/liquidation';
  
  public static readonly account = '/home/account';
  public static readonly userProfile = '/home/account/user-profile';
  public static readonly userList = '/home/account/list';
  public static readonly userAccess = '/home/account/access';
  public static readonly manageApprover = '/home/account/manage-approvers';
  public static readonly userAudits = '/home/account/audits';

  public static readonly accountPayable = '/home/account-payabale';
  public static readonly accountPayableCategories = '/home/account-payabale/categories';
  public static readonly accountPayableManage = '/home/account-payabale/management';

  public static readonly accountReceivable = '/home/account-receivable';
  public static readonly accountReceivableCategories = '/home/account-receivable/categories';
  public static readonly accountReceivableManage = '/home/account-receivable/management';


  public static readonly err404 = '/auth/error/404';
  public static readonly err500 = '/auth/error/500';

  public static readonly tabName = {
    disburesement: '/disburesement',
    prs: '/prs',
  }

  private constructor() { }
}