import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManageApproverMatDialogRefData } from '../../manage-approver.component';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { ManageApproverService } from '../../manage-approver-service/manage-approver.service';
import { PromptMessage } from 'src/app/accountancy/utilities/classes/promp-message.class';
import { finalize, of, Subscription, switchMap, tap } from 'rxjs';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';

@Component({
  selector: 'app-delete-manage-approver',
  templateUrl: './delete-manage-approver.component.html',
  styleUrls: ['./delete-manage-approver.component.scss']
})
export class DeleteManageApproverComponent {

  public readonly prompt = new PromptMessage();

  constructor(
    public loader: LoaderService,

    @Inject(MAT_DIALOG_DATA) public data: ManageApproverMatDialogRefData,
    private _dialogRef: MatDialogRef<DeleteManageApproverComponent>,
    private _manageApproverS: ManageApproverService,
  ) { }

  public onDelete() {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._manageApproverS.deleteApprovalLevel(this.data.ApprovalLevel?.ID ?? -1).pipe(
        finalize(() => {
          this.loader.finish();
          subs.unsubscribe();
        })
      ))
    ).subscribe(Helper.api.handleStatusResponse({
      prompt: this.prompt,
      successMessage: '',
      onSuccess: () => {
        this.data.reloader$.next();
        this._dialogRef.close();
      }
    }));
  }

  public onCancel() {
    this._dialogRef.close();
  }

}
