import { Component, Inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { PromptMessage } from 'src/app/accountancy/utilities/classes/promp-message.class';
import { WaitHandler } from 'src/app/accountancy/utilities/classes/wait-handler.class';
import { LiquidationService } from '../../../../liquidation-service/liquidation.service';
import { GlobalApiService } from 'src/app/accountancy/utilities/services/global-api/global-api.service';
import { finalize, map, of, Subscription, switchMap, tap } from 'rxjs';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';

@Component({
  selector: 'app-dialog-liquidation-receipt',
  templateUrl: './dialog-liquidation-receipt.component.html',
  styleUrls: ['./dialog-liquidation-receipt.component.scss']
})
export class DialogLiquidationReceiptComponent {

  public readonly Helper = Helper;

  public readonly waitHandler = new WaitHandler();
  public readonly prompt = new PromptMessage();

  public readonly fgTR = this._newFgTR();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LiquidationModifiedInterface,

    private _lS: LiquidationService,
    private _globalS: GlobalApiService,
    private _dialogRef: MatDialogRef<DialogLiquidationReceiptComponent>
  ) { }

  public onClose() {
    this._dialogRef.close();
  }

  public onSubmit() {
    if (Helper.getInvalidControls(this.fgTR).length) {
      this.prompt.set(Helper.errorMessage.requiredFields, "alert alert-danger");
      return;
    }

    this.prompt.clear();


    const formData = this.fgTR.value;
    const [year, month, date, day, h, m, s, ms] = Helper.date.dateDestruct(new Date());
    const fileName = `LIQ_${year}${month}${date}${day}${h}${m}${s}${ms}_${formData.Proof?.name}`;

    const subs: Subscription = of(null).pipe(
      tap(() => this.waitHandler.newRequest()),
      switchMap(() => this._globalS.getUploadFileUrl(formData.Proof as File, fileName, "Receipts").pipe(
        finalize(() => this.waitHandler.finishRequest()),
        map(values => values.body?.presignedURL),
        switchMap(presignedURL => of(presignedURL).pipe(
          tap(() => this.waitHandler.newRequest()),
          switchMap(presignedURL => this._globalS.putFileInServer(presignedURL ?? '', formData.Proof as File).pipe(
            finalize(() => this.waitHandler.finishRequest()),
            switchMap(() => of(null).pipe(
              tap(() => this.waitHandler.newRequest()),
              switchMap(() => this._lS.fileLiquidation(this.data.ID, fileName, formData.Total_Cost ?? -1).pipe(
                finalize(() => this.waitHandler.finishRequest()),
                switchMap(() => of(null).pipe(
                  tap(() => this.waitHandler.newRequest()),
                  switchMap(() => this._lS.updateLiquidationStatus(this.data.ID, this.data.NextStatus ?? 'No Status').pipe(
                    finalize(() => {
                      this.waitHandler.finishRequest();
                      subs.unsubscribe();
                    }),
                  ))
                ))
              ))
            ))
          ))
        ))
      ))
    ).subscribe(Helper.api.handleStatusResponse({
      prompt: this.prompt,
      successMessage: "Successfully saved",
      onSuccess: () => {
        this.fgTR.reset();
        this.data.reloader$.next();
        this._dialogRef.close();
      },
    }));
  }

  public onUploadProof(e: Event) {
    const files = (e.target as HTMLInputElement).files as FileList;
    this.fgTR.get('Proof')?.patchValue(files[0]);
  }

  private _newFgTR() {
    return new FormGroup({
      Proof: Helper.reactiveForms.control<File>(undefined, [Validators.required]),
      Total_Cost: Helper.reactiveForms.control<number>(this.data.CA_Request.Amount ? Number(this.data.CA_Request.Amount) : undefined, [Validators.required]),
    });
  }
}
