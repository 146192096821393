import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, finalize, map, startWith, switchMap, tap } from 'rxjs';
import { AccSearchTipComponent } from 'src/app/accountancy/custom-components/acc-search-tip/acc-search-tip.component';
import { Pagination } from 'src/app/accountancy/utilities/classes/pagination.class';
import { CashAdvanceModifiedInterface } from 'src/app/accountancy/utilities/interfaces/cash-advance.interface';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { CashAdvanceService } from '../../cash-advance-service/cash-advance.service';
import { AccMessageDialogComponent, AccMessageDialogInterface } from 'src/app/accountancy/custom-components/acc-message-dialog/acc-message-dialog.component';
import { CaViewDetailsComponent } from '../tab-ca-all-request/ca-view-details/ca-view-details.component';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';

@Component({
  selector: 'app-tab-ca-by-me',
  templateUrl: './tab-ca-by-me.component.html',
  styleUrls: ['./tab-ca-by-me.component.scss']
})
export class TabCaByMeComponent {

  public readonly pagination = new Pagination();
  public readonly caList$ = new BehaviorSubject<CashAdvanceModifiedInterface[]>([]);

  private readonly _subsGetCaList = this._getSubsGetCaList();

  constructor(
    public loader: LoaderService,

    private _loginS: LoginService,
    private _caS: CashAdvanceService,
    private _matDialog: MatDialog,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetCaList.unsubscribe();
  }

  public dialogs = {
    openSearch: () => this._matDialog.open(AccSearchTipComponent),
    openViewMore: (ca: CashAdvanceModifiedInterface) => this._matDialog.open(CaViewDetailsComponent, { data: ca }),
    openPrint: async () => {
      const selectedCA = this.caList$.getValue().filter(v => v.checked);

      // MessageBox Value when there is a selected CA
      const messageBoxData: AccMessageDialogInterface = {
        title: 'Generating PDF',
        message: 'Please wait. Your PDF is being generated.',
        disableClodeButton: true,
        callBackDelay: 3000,
        callBack: (dialog) => dialog.close(),
      };

      // MessageBox value when there is no selected CA
      const messageBoxDataFailed: AccMessageDialogInterface = {
        title: 'Information',
        message: 'Please select at least 1 item to be generated.',
      };

      if (selectedCA.length) {

        this.loader.request();

        // Generate PDF of selected CA
        await this._caS.printSelectedCAs(selectedCA);

        // End request
        this.loader.finish();

        this._matDialog.open(AccMessageDialogComponent, { data: messageBoxData, disableClose: true });
      } else {

        // Open dialog with error message
        this._matDialog.open(AccMessageDialogComponent, { data: messageBoxDataFailed, disableClose: true });
      }
    }
  }

  public transformStatus(status: string) {
    if (status == "Done") return 'success';
    if (status == "Disapproved") return 'danger';
    return 'warning';
  }

  private _getSubsGetCaList() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query)),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => this.loader.request()),
      switchMap(([query, limit]) => this._caS.getAllCashAdvanceRequest(this.pagination.start, limit, query, this._loginS.userData?.ID).pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body),
        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.caList$.next(values?.data.map(v => ({ ...v, checked: false } as CashAdvanceModifiedInterface)) ?? []);
        }),
      ))
    ).subscribe();
  }

}
