import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DisbursementRoutingModule } from './disbursement-routing.module';
import { CashAdvanceComponent } from './cash-advance/cash-advance.component';
import { LiquidationComponent } from './liquidation/liquidation.component';
import { CustomComponentsModule } from '../../custom-components/custom-components.module';
import { DisbursementComponent } from './disbursement.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccMaterialsModule } from '../../utilities/modules/acc-materials/acc-materials.module';
import { AccCustomPipeModule } from '../../utilities/modules/acc-custom-pipe/acc-custom-pipe.module';
import { ReimbursementComponent } from './reimbursement/reimbursement.component';
import { ManageApproverComponent } from './manage-approver/manage-approver.component';
import { AddManageApproverComponent } from './manage-approver/manage-approver-forms/add-manage-approver/add-manage-approver.component';
import { EditManageApproverComponent } from './manage-approver/manage-approver-forms/edit-manage-approver/edit-manage-approver.component';
import { DeleteManageApproverComponent } from './manage-approver/manage-approver-forms/delete-manage-approver/delete-manage-approver.component';
import { TabCaNewTransactionComponent } from './cash-advance/tabs/tab-ca-new-transaction/tab-ca-new-transaction.component';
import { TabCaStatusComponent } from './cash-advance/tabs/tab-ca-status/tab-ca-status.component';
import { TabCaByMeComponent } from './cash-advance/tabs/tab-ca-by-me/tab-ca-by-me.component';
import { TabCaAllRequestComponent } from './cash-advance/tabs/tab-ca-all-request/tab-ca-all-request.component';
import { CaViewDetailsComponent } from './cash-advance/tabs/tab-ca-all-request/ca-view-details/ca-view-details.component';
import { TabReimbursementAllRequestComponent } from './reimbursement/tabs/tab-reimbursement-all-request/tab-reimbursement-all-request.component';
import { TabReimbursementByMeComponent } from './reimbursement/tabs/tab-reimbursement-by-me/tab-reimbursement-by-me.component';
import { TabReimbursementNewTransactionComponent } from './reimbursement/tabs/tab-reimbursement-new-transaction/tab-reimbursement-new-transaction.component';
import { TabReimbursementStatusComponent } from './reimbursement/tabs/tab-reimbursement-status/tab-reimbursement-status.component';
import { ReimbursementViewDetailsComponent } from './reimbursement/tabs/tab-reimbursement-all-request/reimbursement-view-details/reimbursement-view-details.component';
import { ReceiveReimbursementComponent } from './reimbursement/tabs/tab-reimbursement-by-me/receive-reimbursement/receive-reimbursement.component';
import { TabForLiquidationComponent } from './liquidation/tabs/tab-for-liquidation/tab-for-liquidation.component';
import { TabForCashReturnComponent } from './liquidation/tabs/tab-for-cash-return/tab-for-cash-return.component';
import { DialogLiquidationToReimbursementComponent } from './liquidation/tabs/tab-for-liquidation/liquidation-dialogs/dialog-liquidation-to-reimbursement/dialog-liquidation-to-reimbursement.component';
import { DialogLiquidationToCashReturnComponent } from './liquidation/tabs/tab-for-liquidation/liquidation-dialogs/dialog-liquidation-to-cash-return/dialog-liquidation-to-cash-return.component';
import { ViewDetailsCaRequestLiquidationComponent } from './liquidation/view-details-ca-request-liquidation/view-details-ca-request-liquidation.component';
import { DialogLiquidationReceiptComponent } from './liquidation/tabs/tab-for-liquidation/liquidation-dialogs/dialog-liquidation-receipt/dialog-liquidation-receipt.component';


@NgModule({
  declarations: [
    CashAdvanceComponent,
    LiquidationComponent,
    DisbursementComponent,
    ReimbursementComponent,
    ManageApproverComponent,
    AddManageApproverComponent,
    EditManageApproverComponent,
    DeleteManageApproverComponent,
    TabCaNewTransactionComponent,
    TabCaStatusComponent,
    TabCaByMeComponent,
    TabCaAllRequestComponent,
    CaViewDetailsComponent,
    TabReimbursementAllRequestComponent,
    TabReimbursementByMeComponent,
    TabReimbursementNewTransactionComponent,
    TabReimbursementStatusComponent,
    ReimbursementViewDetailsComponent,
    ReceiveReimbursementComponent,
    TabForLiquidationComponent,
    TabForCashReturnComponent,
    DialogLiquidationToReimbursementComponent,
    DialogLiquidationToCashReturnComponent,
    ViewDetailsCaRequestLiquidationComponent,
    DialogLiquidationReceiptComponent
  ],
  imports: [
    CommonModule,
    DisbursementRoutingModule,
    CustomComponentsModule,

    FormsModule,
    ReactiveFormsModule,
    AccMaterialsModule,
    AccCustomPipeModule
  ]
})
export class DisbursementModule { }
