<div class="flex justify-between items-center flex-wrap mb-4">
  <div *ngIf="showTitle" class="text-xl font-semibold">{{tableTitle}}</div>
  <div class="flex items-center gap-2">
    <button *ngIf="showBulkPrint" (click)="onBulkPrint()" class="custom-button custom-button-success">
      <i class="fal fa-print"></i>
    </button>
    <button *ngIf="showBulkDelete" (click)="onBulkDelete()" class="custom-button custom-button-primary">
      <i class="fal fa-trash"></i>
    </button>
  </div>
</div>

<div class="flex flex-wrap md:flex-nowrap justify-between gap-2">
  <div class="mb-4 grow-[1]">
    <mat-select (selectionChange)="pagination.input.setLimitMat($event)" [value]="5" class="form-control">
      <mat-option [value]="5">5 entries</mat-option>
      <mat-option [value]="10">10 entries</mat-option>
      <mat-option [value]="20">20 entries</mat-option>
      <mat-option [value]="50">50 entries</mat-option>
      <mat-option [value]="100">100 entries</mat-option>
      <mat-option [value]="500">500 entries</mat-option>
    </mat-select>
  </div>
  <div class="mb-4 shrink-[1] lg:grow-[1]"></div>
  <div class="mb-4 grow-[1]">
    <div class="form-control-group">
      <div class="form-control-group-label">
        <i class="fal fa-search"></i>
      </div>
      <input (input)="pagination.input.setSearch($event)" type="text" placeholder="Search"
        class="form-control-group-input">
    </div>
  </div>
</div>

<div class="relative overflow-x-auto mb-4" #tableContainer>
  <ng-content></ng-content>
</div>

<div class="flex justify-between flex-wrap gap-2">
  <div class="mb-4">
    <nav>
      <ul class="inline-flex flex-wrap text-base">
        <li>
          <button [disabled]="pagination.hasPrev" (click)="pagination.goToPage(pagination.currentPage - 1)"
            type="button" class="custom-button">
            <i class="fal fa-chevron-left"></i>
          </button>
        </li>
        <li>
          <button *ngIf="pagination.totalPage>=4 && pagination.currentPage>3" (click)="pagination.goToPage(1)"
            type="button" class="custom-button">
            <i class="fal fa-chevron-double-left"></i>
          </button>
        </li>
        <li *ngFor="let p of Helper.array.makeArrayOfNumber(
            pagination.currentPage > 3 ? pagination.currentPage-3 : 0, 
            pagination.currentPage < ((pagination.totalPage+1)-3) ? pagination.currentPage+2 : pagination.totalPage
          )">
          <button (click)="pagination.goToPage(p)" type="button"
            class="{{pagination.currentPage==p?'custom-button-primary-filled':''}} custom-button">
            {{p}}
          </button>
        </li>
        <li>
          <button *ngIf="pagination.totalPage>=4 && pagination.currentPage< ((pagination.totalPage+1)-3)"
            (click)="pagination.goToPage(pagination.totalPage)" type="button" class="custom-button">
            <i class="fal fa-chevron-double-right"></i>
          </button>
        </li>
        <li>
          <button [disabled]="pagination.hasNext" (click)="pagination.goToPage(pagination.currentPage + 1)"
            type="button" class="custom-button">
            <i class="fal fa-chevron-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
  <div class="mb-4 grow-[1]"></div>
  <div class="mb-4 grow-[1] text-end">
    Showing entries {{pagination.start+1}} to {{pagination.end}} of {{pagination.totalCount}}
  </div>
</div>