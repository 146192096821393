import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap, tap } from 'rxjs';
import { AccSearchTipComponent } from 'src/app/accountancy/custom-components/acc-search-tip/acc-search-tip.component';
import { Pagination } from 'src/app/accountancy/utilities/classes/pagination.class';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { LiquidationService } from '../../liquidation-service/liquidation.service';
import { AccTableComponent } from 'src/app/accountancy/custom-components/acc-table/acc-table.component';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { CashReturnChangeStatusComponent } from './cash-return-change-status/cash-return-change-status.component';

@Component({
  selector: 'app-tab-for-cash-return',
  templateUrl: './tab-for-cash-return.component.html',
  styleUrls: ['./tab-for-cash-return.component.scss']
})
export class TabForCashReturnComponent {

  public readonly pagination = new Pagination();
  public readonly crList$ = new BehaviorSubject<LiquidationModifiedInterface[]>([]);

  private readonly _subsGetCaList = this._getSubsGetCrList();

  constructor(
    public loader: LoaderService,

    private _loginS: LoginService,
    private _liquidationS: LiquidationService,
    private _matDialog: MatDialog,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetCaList.unsubscribe();
  }

  public dialogs = {
    openSearch: () => this._matDialog.open(AccSearchTipComponent),
    openViewMore: (ca: LiquidationModifiedInterface) => this._matDialog.open(CashReturnChangeStatusComponent, { data: { ...ca, reloader$: this.pagination.reloader$ } as LiquidationModifiedInterface }),
    openPrint: async (table: AccTableComponent) => await table.printTable(),
  }

  public transformStatus(status: string) {
    if (status == "Done") return 'green';
    if (status == "Disapproved") return 'red';
    return 'yellow';
  }

  private _getSubsGetCrList() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query), debounceTime(500), distinctUntilChanged()),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => this.loader.request()),
      switchMap(([query, limit]) => this._liquidationS.getForCashReturn(this.pagination.start, limit, query, null, this._loginS.userData?.ID).pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body),
        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.crList$.next(values?.data.map(v => ({ ...v, checked: false } as LiquidationModifiedInterface)) ?? []);
        }),
      ))
    ).subscribe();
  }

}
