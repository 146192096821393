import { Injectable } from '@angular/core';
import { HttpApiService } from '../http-api/http-api.service';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { HttpResponse } from '@angular/common/http';
import { AccChartOfAccountCategoryInterface, AccChartOfAccountInterface, AccClientDetachmentInterface, AccClientDetailedInterface, AccClientItemInListInterface, ApiGetResponse, ApiPostResponse, ApiPutResponse, DetachmentInterface, GeneralLedgerInterface, TrainingAPInterface } from '../../interfaces/global.interface';
import { Helper } from '../../classes/helper.class';
import { BillingStatementInterface } from '../../interfaces/billing-statement.interface';
import { AccountPayableAssetInterface, AccountPayableInterface, AccountPayableModifiedInterface } from '../../interfaces/account-payable.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountingApiService {


  private readonly _sendRequest = {
    sendGetRequest: <T>(url: string) => this._api.sendGetRequest<T>(url, this._loginS.getTokenHeader),
    sendDeleteRequest: <T>(url: string) => this._api.sendDeleteRequest<T>(url, this._loginS.getTokenHeader),
    sendPutRequest: <T>(url: string, body: Object) => this._api.sendPutRequest<T>(url, body, this._loginS.getTokenHeader),
    sendPostRequest: <T>(url: string, body: Object) => this._api.sendPostRequest<T>(url, body, this._loginS.getTokenHeader),
    sendPatchRequest: <T>(url: string, body: Object) => this._api.sendPatchRequest<T>(url, body, this._loginS.getTokenHeader),

    hris: this._api.hris,
  };

  constructor(
    private _api: HttpApiService,
    private _loginS: LoginService,
  ) { }

  public readonly trainingAP = {
    gellAll: (start?: number | null, limit?: number | null, query?: string | null, other?: Object | null) => this._sendRequest
      .sendGetRequest<HttpResponse<ApiGetResponse<TrainingAPInterface>>>(
        Helper.url.toParams(`/api/v1/training-events/no-payment-details`, {
          Start: start,
          Limit: limit,
          Search: query,
          ...other
        })
      ),

    updatePaymentDetails: (trainingId: number, body: Object) => this._sendRequest
      .sendPutRequest(`/api/v1/training-event/${trainingId}/payment-details`, body),
  }

  public readonly address = {
    getCities: () => this._sendRequest.hris
      .sendGetRequest<HttpResponse<any>>(`/cities`),
  }

  public readonly dashboard = {
    getBirthdays: () => this._sendRequest.hris
      .sendGetRequest<HttpResponse<any>>(`/birthday-celebrants`),
  }

  public readonly billing = {

    generate: (body: object) => this._sendRequest
      .sendPostRequest<HttpResponse<{ message?: string | null, data?: BillingStatementInterface[] | null }>>(`/api/v1/create-billing`, body),

    getById: (billingId: number) => this._sendRequest
      .sendGetRequest<HttpResponse<{ data: BillingStatementInterface }>>(`/api/v1/billing-statement/${billingId}`),

    updateGeneratedById: (billingId: number, body: {
      rID: number,
      TotalAmount?: number | null,
      Status?: string | null,
      BillDate?: string | null,
      ApprovalStatus?: string | null,
      AmountPaid?: number | null,
      Balance?: number | null,
      PaymentMethod?: Object | null,
      Remarks?: string | null
    }) => this._sendRequest
      .sendPutRequest(`/api/v1/billing-statement/${billingId}`, body),

  }

  public readonly generalLedger = {

    gellAll: (start?: number | null, limit?: number | null, query?: string | null, other?: Object | null) => this._sendRequest
      .sendGetRequest<HttpResponse<ApiGetResponse<GeneralLedgerInterface>>>(
        Helper.url.toParams(`/api/v1/general-ledger`, {
          Start: start,
          Limit: limit,
          Search: query,
          ...other,
        })
      ),
  }

  public readonly clients = {

    getClientList: (start?: number | null, limit?: number | null, query?: string | null, other?: Object | null) => this._sendRequest
      .sendGetRequest<HttpResponse<ApiGetResponse<AccClientItemInListInterface>>>(
        Helper.url.toParams(`/api/v1/clients`, {
          Start: start,
          Limit: limit,
          Search: query,
          ...other
        })
      ),

    getClientById: (clientId: number) => this._sendRequest
      .sendGetRequest<HttpResponse<{ data: AccClientDetailedInterface }>>(
        `/api/v1/client/${clientId}`,
      ),

    getClientDetachments: (clientId: number, start?: number | null, limit?: number | null, query?: string | null, other?: Object | null) => this._sendRequest
      .sendGetRequest<HttpResponse<ApiGetResponse<AccClientDetachmentInterface>>>(
        Helper.url.toParams(`/api/v1/client/${clientId}/detachments`, {
          Start: start,
          Limit: limit,
          Search: query,
          ...other
        })
      ),

    updateClientById: (clientId: number, body: Object) => this._sendRequest
      .sendPutRequest(`/api/v1/client/${clientId}`, body),

    getAllAvailableDetachments: () => this._sendRequest
      .sendGetRequest<HttpResponse<ApiGetResponse<DetachmentInterface>>>(`/api/v1/detachments?Filter=only-available`),

    updateClientDetachment: (detachmentId: number, body: Object) => this._sendRequest
      .sendPutRequest(`/api/v1/detachment/${detachmentId}`, body),
  }

  public readonly chartOfAccounts = {

    getChartOfAccountList: (start?: number | null, limit?: number | null, query?: string | null, other?: Object | null) => this._sendRequest
      .sendGetRequest<HttpResponse<ApiGetResponse<AccChartOfAccountInterface>>>(
        Helper.url.toParams(`/api/v1/chart-of-accounts`, {
          Start: start,
          Limit: limit,
          Search: query,
          ...other
        })
      ),

    newChartOfAccount: (body: Object) => this._sendRequest
      .sendPostRequest(`/api/v1/chart-account`, body),

    updateChartOfAccount: (id: number, body: Object) => this._sendRequest
      .sendPutRequest(`/api/v1/chart-account/${id}`, body),

    deleteChartOfAccount: (id: number) => this._sendRequest
      .sendDeleteRequest(`/api/v1/chart-account/${id}`),

    category: {

      getCategoryList: (start?: number | null, limit?: number | null, query?: string | null, other?: Object | null) => this._sendRequest
        .sendGetRequest<HttpResponse<ApiGetResponse<AccChartOfAccountCategoryInterface>>>(
          Helper.url.toParams(`/api/v1/accounts-categories`, {
            Start: start,
            Limit: limit,
            Search: query,
            ...other,
          })
        ),

      newCategory: (body: Object) => this._sendRequest
        .sendPostRequest(`/api/v1/accounts-category`, body),

      updateCategory: (id: number, body: Object) => this._sendRequest
        .sendPutRequest(`/api/v1/accounts-category/${id}`, body),

      deleteCategory: (id: number) => this._sendRequest
        .sendDeleteRequest(`/api/v1/accounts-category/${id}`),
    }
  }

  public readonly accountPayable = {

    getApAsset: (start?: number | null, limit?: number | null, query?: string | null, by?: number | null, approver?: number | null, other?: Object | null) => {
      return this._api.sendGetRequest<HttpResponse<ApiGetResponse<AccountPayableAssetInterface>>>(
        Helper.url.toParams(`/api/v1/ap-assets/no-payment-details`, {
          Start: start,
          Limit: limit,
          Search: query,
          RequestedBy: by,
          Approver: approver,
          ...other
        }),
        this._loginS.getTokenHeader
      );
    },

    updateApAsset: (prId: string, body: Object) => {
      return this._api.sendPutRequest<HttpResponse<ApiPutResponse<AccountPayableAssetInterface>>>(
        `/api/v1/ap-asset/${prId}/payment-details`, body, this._loginS.getTokenHeader
      ).pipe(
        map(values => values.body),
      );
    },

    getApWithPaymentDetails: (start?: number | null, limit?: number | null, query?: string | null, by?: number | null, approver?: number | null, other?: Object | null) => {
      return this._api.sendGetRequest<HttpResponse<ApiGetResponse<AccountPayableInterface>>>(
        Helper.url.toParams(`/api/v1/account-payables`, {
          Start: start,
          Limit: limit,
          Search: query,
          RequestedBy: by,
          Approver: approver,
          ...other,
        }),
        this._loginS.getTokenHeader
      );
    },

    newAp: (body: Object) => {
      return this._api.sendPostRequest<HttpResponse<ApiPostResponse<AccountPayableInterface>>>(
        `/api/v1/account-payable`, body, this._loginS.getTokenHeader
      ).pipe(
        map(values => values.body),
      );
    },

    updateAp: (apId: number, body: Object) => {
      return this._api.sendPutRequest<HttpResponse<ApiPutResponse<AccountPayableInterface>>>(
        `/api/v1/account-payable/${apId}`, body, this._loginS.getTokenHeader
      ).pipe(
        map(values => values.body),
      );
    },

    updateApChangeStatus: (apId: number, newStatus: string) => {
      return this._api.sendPutRequest<HttpResponse<ApiPostResponse<AccountPayableInterface>>>(
        `/api/v1/account-payable/${apId}/status`, { Status: newStatus }, this._loginS.getTokenHeader
      ).pipe(
        map(values => values.body)
      );
    },

    updateApMarkVoucherPrinted: (apId: number) => {
      return this._api.sendPostRequest<HttpResponse<any>>(
        `/api/v1/account-payable/${apId}/voucher`, {}, this._loginS.getTokenHeader
      ).pipe(
        map(values => values.body as { Account_Payable_Info: AccountPayableModifiedInterface, ReferenceNumber: string })
      );
    },

  }





}
