import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccTableComponent } from './acc-table/acc-table.component';
import { AccSearchTipComponent } from './acc-search-tip/acc-search-tip.component';
import { AccMessageDialogComponent } from './acc-message-dialog/acc-message-dialog.component';
import { AccMaterialsModule } from '../utilities/modules/acc-materials/acc-materials.module';
import { AccStepperComponent } from './acc-stepper/acc-stepper.component';
import { AccStepperStepComponent } from './acc-stepper-step/acc-stepper-step.component';
import { AccCardCollapseComponent } from './acc-card-collapse/acc-card-collapse.component';
import { AccLoaderComponent } from './acc-loader/acc-loader.component';
import { AccConfirmationComponent } from './acc-confirmation/acc-confirmation.component';
import { AccAutoCompleteComponent } from './acc-auto-complete/acc-auto-complete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccRemarksInputComponent } from './acc-remarks-input/acc-remarks-input.component';
import { AccListComponent } from './acc-list/acc-list.component';
import { AccListServiceComponent } from './acc-list/acc-list-service/acc-list-service.component';



@NgModule({
  declarations: [
    AccTableComponent,
    AccSearchTipComponent,
    AccMessageDialogComponent,
    AccStepperComponent,
    AccStepperStepComponent,
    AccCardCollapseComponent,
    AccLoaderComponent,
    AccConfirmationComponent,
    AccAutoCompleteComponent,
    AccRemarksInputComponent,
    AccListComponent,
    AccListServiceComponent,
  ],
  exports: [
    AccTableComponent,
    AccSearchTipComponent,
    AccMessageDialogComponent,
    AccStepperComponent,
    AccStepperStepComponent,
    AccCardCollapseComponent,
    AccLoaderComponent,
    AccConfirmationComponent,
    AccAutoCompleteComponent,
    AccRemarksInputComponent,
    AccListComponent,
  ],
  imports: [
    CommonModule,
    AccMaterialsModule,

    FormsModule,
    ReactiveFormsModule,
    AccMaterialsModule,
  ],
})
export class CustomComponentsModule { }
