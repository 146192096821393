import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, finalize, forkJoin, map, mergeMap, Observable, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { AccSearchTipComponent } from 'src/app/accountancy/custom-components/acc-search-tip/acc-search-tip.component';
import { Pagination } from 'src/app/accountancy/utilities/classes/pagination.class';
import { CashAdvanceModifiedInterface } from 'src/app/accountancy/utilities/interfaces/cash-advance.interface';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { CashAdvanceService } from '../../cash-advance-service/cash-advance.service';
import { AccMessageDialogComponent, AccMessageDialogInterface } from 'src/app/accountancy/custom-components/acc-message-dialog/acc-message-dialog.component';
import { CaViewDetailsComponent } from '../tab-ca-all-request/ca-view-details/ca-view-details.component';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ApiGetResponse } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { ManageApproversService } from 'src/app/accountancy/home/account-management/manage-approvers/manage-approvers-service/manage-approvers.service';
import { AccTableComponent } from 'src/app/accountancy/custom-components/acc-table/acc-table.component';

@Component({
  selector: 'app-tab-ca-status',
  templateUrl: './tab-ca-status.component.html',
  styleUrls: ['./tab-ca-status.component.scss']
})
export class TabCaStatusComponent {

  public readonly pagination = new Pagination();
  public readonly caList$ = new BehaviorSubject<CashAdvanceModifiedInterface[]>([]);

  private readonly _subsGetCaList = this._getSubsGetCaList();

  constructor(
    public loader: LoaderService,

    private _manageApprovalS: ManageApproversService,
    private _loginS: LoginService,
    private _caS: CashAdvanceService,
    private _matDialog: MatDialog,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetCaList.unsubscribe();
  }

  public onDisapprove(ca: CashAdvanceModifiedInterface) {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._caS.updateStatus(ca.CntrlNumber ?? '', 'Disapproved').pipe(
        finalize(() => {
          this.pagination.reloader$.next();
          this.loader.finish();
          subs.unsubscribe();
        })
      ))
    ).subscribe();
  }

  public onApprove(ca: CashAdvanceModifiedInterface) {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._caS.updateStatus(ca.CntrlNumber ?? '', ca.NextStatus ?? 'No Status').pipe(
        finalize(() => {
          this.pagination.reloader$.next();
          this.loader.finish();
          subs.unsubscribe();
        })
      )),
    ).subscribe();
  }

  public dialogs = {
    openSearch: () => this._matDialog.open(AccSearchTipComponent),
    openViewMore: (ca: CashAdvanceModifiedInterface) => this._matDialog.open(CaViewDetailsComponent, { data: ca }),
    openPrint: async (print: AccTableComponent) => await print.printTable(),
  }

  public transformStatus(status: string) {
    if (status == "Done") return 'success';
    if (status == "Disapproved") return 'danger';
    return 'warning';
  }

  private _getSubsGetCaList() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query)),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => this.loader.request()),
      switchMap(([query, limit]) => this._caS.getAllCashAdvanceRequest(this.pagination.start, limit, query, null, this._loginS.userData?.ID).pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body),
        // mergeMap(values => {
        //   if (!values) return of({ data: [], total_rows: 0 } as ApiGetResponse<CashAdvanceModifiedInterface>);

        //   const modifiedCaList = values?.data.map(v => of(v).pipe(
        //     switchMap(values => this._getNextStatus(values.Status).pipe(
        //       map(nextStatus => ({ ...values, NextStatus: nextStatus } as CashAdvanceModifiedInterface))
        //     ))
        //   ));

        //   if (!modifiedCaList.length) return of(values);

        //   return forkJoin(modifiedCaList).pipe(
        //     map(newList => ({ data: newList, total_rows: values.total_rows } as ApiGetResponse<CashAdvanceModifiedInterface>))
        //   );
        // }),
        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.caList$.next(values?.data.map(v => ({ ...v, checked: false } as CashAdvanceModifiedInterface)) ?? []);
        }),
      ))
    ).subscribe();
  }

  // private _getNextStatus(currentStatus: string): Observable<string | undefined> {
  //   if (currentStatus == "Done" || currentStatus == "Disapproved") return of(undefined);
  //   return of(null).pipe(
  //     tap(() => this.loader.request()),
  //     switchMap(() => this._manageApprovalS.getApprovalLevels("Cash Advance").pipe(
  //       finalize(() => this.loader.finish()),
  //       map(values => {
  //         if (!values) return 'No Status';

  //         const currentIndex = values?.findIndex(v => v.Status == currentStatus);
  //         if (currentIndex < 0) return values[0].Status;

  //         return (currentIndex + 1 == values.length) ? 'Done' : values[currentIndex + 1].Status;
  //       })
  //     ))
  //   )
  // }

}
