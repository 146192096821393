import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ApiRoutes } from 'src/app/accountancy/utilities/classes/api-routes.class';
import { ApiGetResponse, ApiPostResponse, ApprovalLevelInterface } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { HttpApiService } from 'src/app/accountancy/utilities/services/http-api/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class ManageApproverService {

  constructor(

    private _loginS: LoginService,
    private _api: HttpApiService,
  ) { }

  public getApprovalLevels() {
    return this._api.sendGetRequest<HttpResponse<ApiGetResponse<ApprovalLevelInterface>>>(
      ApiRoutes.approvalSetting, this._loginS.getTokenHeader,
    ).pipe(
      map(values => values.body),
    );
  }

  public addApprovalLevel(body: Object) {
    return this._api.sendPostRequest<HttpResponse<ApiPostResponse<ApprovalLevelInterface>>>(
      ApiRoutes.approvalSetting, body, this._loginS.getTokenHeader
    ).pipe(
      map(values => values.body),
    );
  }

  public updateApprovalLevel(approvalLevelId: number, body: Object) {
    return this._api.sendPutRequest<HttpResponse<ApiPostResponse<ApprovalLevelInterface>>>(
      `${ApiRoutes.approvalSetting}/${approvalLevelId}`, body, this._loginS.getTokenHeader
    ).pipe(
      map(values => values.body),
    );
  }

  public deleteApprovalLevel(approvalLevelId: number) {
    return this._api.sendDeleteRequest<HttpResponse<ApiPostResponse<ApprovalLevelInterface>>>(
      `${ApiRoutes.approvalSetting}/${approvalLevelId}`, this._loginS.getTokenHeader
    ).pipe(
      map(values => values.body),
    );
  }


}
