import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationError } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AccMessageDialogService } from './accountancy/custom-components/acc-message-dialog/acc-message-dialog-service/acc-message-dialog.service';
import { AccMessageDialogInterface } from './accountancy/custom-components/acc-message-dialog/acc-message-dialog.component';
import { Routes } from './accountancy/utilities/classes/routes.class';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  public readonly routes = Routes;
  public readonly title = 'AGF - Financial Management And Accounting System';

  private readonly _subsNavErrEvent = this._getSubsNavErrEvent();

  constructor(
    private router: Router,
    private _mDialogS: AccMessageDialogService
  ) {
    if (!environment.production) console.log({ environment });
  }

  public ngOnDestroy(): void {
    this._subsNavErrEvent.unsubscribe();
  }

  private _getSubsNavErrEvent() {
    return this.router.events.subscribe(e => {
      if (e instanceof NavigationError) this._handleNavErrEvent(e);
      if (e instanceof NavigationEnd) this._handleNavEndEvent(e);
    });
  }

  private _handleNavErrEvent(e: NavigationError) {
    this._mDialogS.open({
      message: "Internal Error: Message Administrator",
      messageType: "text-red-500",
      disableClodeButton: false,
      onClose: (dialog, dataPassed) => {
        console.log({ r: this.routes.err404 })
        this.router.navigate([this.routes.err404]);
      },
    } as AccMessageDialogInterface, true);
  }

  private _handleNavEndEvent(e: NavigationEnd) {
    window.scrollTo(0, 0);
  }
}
