import { Component, Inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, of, Subscription, switchMap, tap } from 'rxjs';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { PromptMessage } from 'src/app/accountancy/utilities/classes/promp-message.class';
import { WaitHandler } from 'src/app/accountancy/utilities/classes/wait-handler.class';
import { LiquidationService } from '../../../../liquidation-service/liquidation.service';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { GlobalApiService } from 'src/app/accountancy/utilities/services/global-api/global-api.service';

@Component({
  selector: 'app-dialog-liquidation-new',
  templateUrl: './dialog-liquidation-new.component.html',
  styleUrls: ['./dialog-liquidation-new.component.scss']
})
export class DialogLiquidationNewComponent {

  public readonly Helper = Helper;

  public readonly waitHandler = new WaitHandler();
  public readonly prompt = new PromptMessage();

  public readonly fgNewLiquidation = this._newFgNewLiquidation();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LiquidationModifiedInterface,

    private _globalS: GlobalApiService,
    private _lS: LiquidationService,
    private _matDialogRef: MatDialogRef<DialogLiquidationNewComponent>,
  ) { }

  public onClose() {
    this._matDialogRef.close();
  }

  public onSubmit() {
    const formData = this.fgNewLiquidation.value;

    const [year, month, date, day, h, m, s, ms] = Helper.date.dateDestruct(new Date());
    const fileName = `LIQ_${year}${month}${date}${day}${h}${m}${s}${ms}_${formData.Proof?.name.trim()}`;

    const body = {
      ...formData,
      Transaction_Date: Helper.date.format(formData.Transaction_Date, 1, '-'),
      Due_Date: Helper.date.format(formData.Due_Date, 1, '-'),
      Proof: fileName,
      Total_Cost: Number(formData.Total_Cost),
    }

    const subs: Subscription = of(null).pipe(
      switchMap(() => this._globalS.uploadFileWrapped(this.waitHandler, fileName, formData.Proof as File, "Receipts").pipe(
        switchMap(() => this._lS.fileLiquidation(this.data.ID, body).pipe(
          switchMap(() => of(null).pipe(
            tap(() => this.waitHandler.newRequest()),
            switchMap(() => this._lS.updateLiquidationStatus(this.data.ID, this.data.NextStatus ?? 'No Status').pipe(
              finalize(() => {
                this.waitHandler.finishRequest();
                subs.unsubscribe();
              }),
            ))
          ))
        ))
      ))
    ).subscribe(Helper.api.handleStatusResponse({
      prompt: this.prompt,
      successMessage: "Successfully saved",
      onSuccess: () => {
        this.fgNewLiquidation.reset();
        this.data.reloader$.next();
        this._matDialogRef.close();
      },
    }))
  }

  public onUploadProof(e: Event) {
    const files = (e.target as HTMLInputElement).files as FileList;
    this.fgNewLiquidation.get('Proof')?.patchValue(files[0]);
  }

  private _newFgNewLiquidation() {
    return new FormGroup({
      Transaction_Date: Helper.reactiveForms.control<string>(undefined, [Validators.required]),
      Due_Date: Helper.reactiveForms.control<string>(undefined, [Validators.required]),
      Total_Cost: Helper.reactiveForms.control<number>(this.data.CA_Request.Amount, [Validators.required]),
      Proof: Helper.reactiveForms.control<File>(undefined, [Validators.required]),
    });
  }

}
