import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helper } from '../../utilities/classes/helper.class';
import { CustomValidator } from '../../utilities/validators/custom-validator';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-acc-auto-complete',
  templateUrl: './acc-auto-complete.component.html',
  styleUrls: ['./acc-auto-complete.component.scss']
})
export class AccAutoCompleteComponent implements OnInit {

  public readonly filteredChoices$ = new BehaviorSubject<string[]>([]);
  public readonly fg = this._newFg();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AccAutoCompleteInterface,

    private _dialogRef: MatDialogRef<AccAutoCompleteComponent>,
  ) { }

  public ngOnInit(): void {
    this._filterList();
  }

  public onSubmit() {
    if (Helper.getInvalidControls(this.fg, true).length) {
      return;
    }

    this.data.onSubmit(this.fg.value.Search ?? '', this._dialogRef);
  }

  public onClose() {
    this._dialogRef.close();
  }

  private _newFg() {
    const fc = Helper.reactiveForms.control<string>(this.data.value, [Validators.required, CustomValidator.existIn(this.data.choices)]);
    fc.valueChanges.subscribe(query => this._filterList(query));

    return new FormGroup({
      Search: fc,
    });
  }

  private _filterList(query?: string | null) {
    const filteredData = this.data.choices
      .filter(v => {
        const exclude = this.data.exclude;
        if (!exclude) return true;
        if (exclude.map(ex => Helper.string.trimLowerCase(ex)).includes(v)) return false;
        return true;
      })
      .filter(v => Helper.string.trimLowerCase(v).includes(Helper.string.trimLowerCase(query)));

    this.filteredChoices$.next(filteredData);
  }

}

export interface AccAutoCompleteInterface {
  value?: string | null,
  label?: string | null,
  exclude?: string[] | null,
  choices: string[],
  onSubmit: (value: string, ref: MatDialogRef<any>) => void,
}