<form [formGroup]="fg" (submit)="onSubmit()" class="card md:min-w-[500px]">
  <div class="card-header">{{data.label ?? 'Search'}}</div>
  <div class="card-body">
    <input formControlName="Search" type="text" class="form-control" placeholder="Search" matInput [matAutocomplete]="auto">
    <mat-autocomplete #auto>
      <ng-container *ngIf="filteredChoices$|async as filteredChoices">
        <mat-option *ngIf="!filteredChoices.length&&fg.controls.Search.value" disabled>Nothing found...</mat-option>
        <mat-option *ngFor="let item of filteredChoices" [value]="item">{{item}}</mat-option>
      </ng-container>
    </mat-autocomplete>
    <div *ngIf="fg.controls.Search.invalid&&fg.controls.Search.touched" class="text-sm text-red-400">
      <div *ngIf="fg.controls.Search.errors?.['required']">* This field is required.</div>
      <div *ngIf="fg.controls.Search.errors?.['existIn']">* Value does not exist.</div>
    </div>
  </div>
  <div class="card-footer">
    <div class="form-submit">
      <button type="submit" class="custom-button custom-button-primary">Continue</button>
      <button (click)="onClose()" type="button" class="custom-button">Cancel</button>
    </div>
  </div>
</form>