import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, finalize, map, startWith, switchMap, tap } from 'rxjs';
import { AccSearchTipComponent } from 'src/app/accountancy/custom-components/acc-search-tip/acc-search-tip.component';
import { Pagination } from 'src/app/accountancy/utilities/classes/pagination.class';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { AccMessageDialogComponent, AccMessageDialogInterface } from 'src/app/accountancy/custom-components/acc-message-dialog/acc-message-dialog.component';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ReimbursementModifiedInterface } from 'src/app/accountancy/utilities/interfaces/reimbursement.interface';
import { ReimbursementService } from '../../reimbursement-service/reimbursement.service';
import { ReimbursementViewDetailsComponent } from '../tab-reimbursement-all-request/reimbursement-view-details/reimbursement-view-details.component';

@Component({
  selector: 'app-tab-reimbursement-all-request',
  templateUrl: './tab-reimbursement-all-request.component.html',
  styleUrls: ['./tab-reimbursement-all-request.component.scss']
})
export class TabReimbursementAllRequestComponent {

  public checkAll = false;

  public readonly pagination = new Pagination();
  public readonly reimbursementList$ = new BehaviorSubject<ReimbursementModifiedInterface[]>([]);

  private readonly _subsGetReimbursementList = this._getSubsGetReimbursementList();

  constructor(
    public loader: LoaderService,

    private _loginS: LoginService,
    private _reimbursementS: ReimbursementService,
    private _matDialog: MatDialog,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetReimbursementList.unsubscribe();
  }

  public dialogs = {
    openSearch: () => this._matDialog.open(AccSearchTipComponent),
    openViewMore: (reimbursement: ReimbursementModifiedInterface) => this._matDialog.open(ReimbursementViewDetailsComponent, { data: reimbursement }),
    openPrint: async () => {
      const selectedReimbursement = this.reimbursementList$.getValue().filter(v => v.checked);

      // MessageBox Value when there is a selected CA
      const messageBoxData: AccMessageDialogInterface = {
        title: 'Generating PDF',
        message: 'Please wait. Your PDF is being generated.',
        disableClodeButton: true,
        callBackDelay: 3000,
        callBack: (dialog) => dialog.close(),
      };

      // MessageBox value when there is no selected CA
      const messageBoxDataFailed: AccMessageDialogInterface = {
        title: 'Information',
        message: 'Please select at least 1 item to be generated.',
      };

      if (selectedReimbursement.length) {

        this.loader.request();

        // Generate PDF of selected CA
        await this._reimbursementS.printSelectedRItems(selectedReimbursement);

        // End request
        this.loader.finish();

        // Change all the checkbox to unchecked
        this._changeCheckForReimbursementList(false);

        this._matDialog.open(AccMessageDialogComponent, { data: messageBoxData, disableClose: true });
      } else {

        // Open dialog with error message
        this._matDialog.open(AccMessageDialogComponent, { data: messageBoxDataFailed, disableClose: true });
      }
    }
  }

  public toggleCheckAll(e: Event) {
    const checked = (e.target as HTMLInputElement).checked;
    this._changeCheckForReimbursementList(checked);
  }

  public transformStatus(status: string) {
    if (status == "Done") return 'success';
    if (status == "Disapproved") return 'danger';
    return 'warning';
  }

  private _changeCheckForReimbursementList(newValue: boolean) {
    this.checkAll = newValue;
    this.reimbursementList$.getValue().forEach(v => v.checked = newValue);
  }

  private _getSubsGetReimbursementList() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query)),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => this.loader.request()),
      switchMap(([query, limit]) => this._reimbursementS.getReimbursementRequests(this.pagination.start, limit, query).pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body),
        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.reimbursementList$.next(values?.data.map(v => ({ ...v, checked: false } as ReimbursementModifiedInterface)) ?? []);
        }),
      ))
    ).subscribe();
  }

}
