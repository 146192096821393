import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, finalize, forkJoin, map, mergeMap, Observable, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { AccSearchTipComponent } from 'src/app/accountancy/custom-components/acc-search-tip/acc-search-tip.component';
import { Pagination } from 'src/app/accountancy/utilities/classes/pagination.class';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ApiGetResponse } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { LiquidationService } from '../../liquidation-service/liquidation.service';
import { CashAdvanceService } from '../../../cash-advance/cash-advance-service/cash-advance.service';
import { ViewDetailsCaRequestLiquidationComponent } from '../../view-details-ca-request-liquidation/view-details-ca-request-liquidation.component';
import { AccTableComponent } from 'src/app/accountancy/custom-components/acc-table/acc-table.component';
import { ManageApproversService } from 'src/app/accountancy/home/account-management/manage-approvers/manage-approvers-service/manage-approvers.service';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';

@Component({
  selector: 'app-tab-for-cash-return',
  templateUrl: './tab-for-cash-return.component.html',
  styleUrls: ['./tab-for-cash-return.component.scss']
})
export class TabForCashReturnComponent {

  public readonly pagination = new Pagination();
  public readonly crList$ = new BehaviorSubject<LiquidationModifiedInterface[]>([]);

  private readonly _subsGetCaList = this._getSubsGetCrList();

  constructor(
    public loader: LoaderService,

    private _manageApprovalS: ManageApproversService,
    private _loginS: LoginService,
    private _liquidationS: LiquidationService,
    private _caS: CashAdvanceService,
    private _matDialog: MatDialog,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetCaList.unsubscribe();
  }

  public onDisapprove(cr: LiquidationModifiedInterface) {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._liquidationS.updateCashReturnStatus(cr.ID ?? -1, 'Disapproved').pipe(
        finalize(() => {
          this.pagination.reloader$.next();
          this.loader.finish();
          subs.unsubscribe();
        })
      ))
    ).subscribe();
  }

  public onApprove(cr: LiquidationModifiedInterface) {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._liquidationS.updateCashReturnStatus(cr.ID ?? '', cr.NextStatus ?? 'No Status').pipe(
        finalize(() => {
          this.pagination.reloader$.next();
          this.loader.finish();
          subs.unsubscribe();
        })
      )),
    ).subscribe();
  }

  public dialogs = {
    openSearch: () => this._matDialog.open(AccSearchTipComponent),
    openViewMore: (ca: LiquidationModifiedInterface) => this._matDialog.open(ViewDetailsCaRequestLiquidationComponent, { data: ca }),
    openPrint: async (table: AccTableComponent) => await table.printTable(),
  }

  public transformStatus(status: string) {
    if (status == "Done") return 'success';
    if (status == "Disapproved") return 'danger';
    return 'warning';
  }

  private _getSubsGetCrList() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query)),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => this.loader.request()),
      switchMap(([query, limit]) => this._liquidationS.getForCashReturn(this.pagination.start, limit, query, null, this._loginS.userData?.ID).pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body),
        // mergeMap(values => {
        //   if (!values) return of({ data: [], total_rows: 0 } as ApiGetResponse<LiquidationModifiedInterface>);

        //   const modifiedCaList = values?.data.map(v => of(v).pipe(
        //     switchMap(values => this._getNextStatus(values.Status).pipe(
        //       map(nextStatus => ({ ...values, NextStatus: nextStatus } as LiquidationModifiedInterface))
        //     ))
        //   ));

        //   if (!modifiedCaList.length) return of(values);

        //   return forkJoin(modifiedCaList).pipe(
        //     map(newList => ({ data: newList, total_rows: values.total_rows } as ApiGetResponse<LiquidationModifiedInterface>))
        //   );
        // }),
        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.crList$.next(values?.data.map(v => ({ ...v, checked: false } as LiquidationModifiedInterface)) ?? []);
        }),
      ))
    ).subscribe();
  }

  // private _getNextStatus(currentStatus: string): Observable<string | undefined> {
  //   if (currentStatus == "Done" || currentStatus == "Disapproved") return of(undefined);
  //   return of(null).pipe(
  //     tap(() => this.loader.request()),
  //     switchMap(() => this._manageApprovalS.getApprovalLevels("Cash Refund").pipe(
  //       finalize(() => this.loader.finish()),
  //       map(values => {
  //         if (!values) return 'No Status';

  //         const currentIndex = values?.findIndex(v => v.Status == currentStatus);
  //         if (currentIndex < 0) return values[0].Status;

  //         return (currentIndex + 1 == values.length) ? 'Done' : values[currentIndex + 1].Status;
  //       })
  //     ))
  //   )
  // }

}
