<form [formGroup]="fgFilter" class="grid grid-cols-12 gap-4 mb-4">
  <div class="col-span-12 md:col-span-6">
    <mat-select formControlName="Limit" class="form-control">
      <mat-option [value]="5">5 entries</mat-option>
      <mat-option [value]="10">10 entries</mat-option>
      <mat-option [value]="20">20 entries</mat-option>
      <mat-option [value]="50">50 entries</mat-option>
      <mat-option [value]="100">100 entries</mat-option>
      <mat-option [value]="0">All entries</mat-option>
    </mat-select>
  </div>
  <div class="col-span-12 md:col-span-6">
    <input formControlName="Search" type="text" class="form-control" placeholder="Search">
  </div>
  <div class="col-span-12">
    <div class="mb-4">
      Showing
      <span *ngIf="fgValueList$|async as fgLimit">
        <ng-container *ngIf="fgLimit.length as limit">
          {{limit>list.length?list.length:limit}}
        </ng-container>
      </span>
      of {{list.length}} entries.
    </div>
  </div>
</form>

<form [formGroup]="fgListValue">
  <table [class]="customClass">
    <thead>
      <tr>
        <th>
          <mat-checkbox *ngIf="withCheckbox" [(ngModel)]="checkAllToggle" [ngModelOptions]="{standalone: true}"
            (change)="onSelectAllChange()" matTooltip="Select All"></mat-checkbox>
          {{listTitle}}
        </th>
      </tr>
    </thead>
    <tbody formArrayName="list">
      <ng-container *ngFor="let fg of fgValueList$|async">
        <tr [formGroup]="fg">
          <td>
            <ng-container *ngTemplateOutlet="withCheckbox?WithCheckbox:WithoutCheckbox"> </ng-container>

            <ng-template #WithCheckbox>
              <mat-checkbox [formControl]="fg.controls.checked">{{fg.controls.value.value}}</mat-checkbox>
            </ng-template>

            <ng-template #WithoutCheckbox>
              {{fg.controls.value.value}}
            </ng-template>

          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</form>