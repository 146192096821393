import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';

@Component({
  selector: 'app-disbursement',
  templateUrl: './disbursement.component.html',
  styleUrls: ['./disbursement.component.scss']
})
export class DisbursementComponent {

  public readonly routes = Routes;

}
