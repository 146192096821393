import { Component, OnDestroy } from '@angular/core';
import { Routes } from '../../utilities/classes/routes.class';
import { WaitHandler } from '../../utilities/classes/wait-handler.class';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, finalize, map, of, startWith, Subject, switchMap, tap } from 'rxjs';
import { AccountingApiService } from '../../utilities/services/accounting-api/accounting-api.service';
import { Pagination } from '../../utilities/classes/pagination.class';
import { GeneralLedgerInterface } from '../../utilities/interfaces/global.interface';

@Component({
  selector: 'app-general-ledger',
  templateUrl: './general-ledger.component.html',
  styleUrls: ['./general-ledger.component.scss']
})
export class GeneralLedgerComponent implements OnDestroy {

  public readonly routes = Routes;

  public readonly waitHandler = new WaitHandler();
  public readonly pagination = new Pagination();

  public readonly generalLedgerList$ = new BehaviorSubject<GeneralLedgerInterface[]>([]);
  private readonly _subsGetGLList = this._getSubsGetGL();

  constructor(
    private _accApiS: AccountingApiService,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetGLList.unsubscribe();
  }

  private _getSubsGetGL() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query), debounceTime(500), distinctUntilChanged()),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => {
        this.waitHandler.newRequest();
        this.generalLedgerList$.next([]);
      }),
      switchMap(([query, limit]) => this._accApiS.generalLedger.gellAll(this.pagination.start, limit, query).pipe(
        finalize(() => this.waitHandler.finishRequest()),
        map(values => values.body),
        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.generalLedgerList$.next(values?.data ?? []);
        }),
      ))
    ).subscribe();
  }


}
