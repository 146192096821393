import { Component } from '@angular/core';
import { LiquidationService } from '../../liquidation-service/liquidation.service';
import { BehaviorSubject, combineLatest, finalize, forkJoin, map, mergeMap, Observable, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { Pagination } from 'src/app/accountancy/utilities/classes/pagination.class';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { MatDialog } from '@angular/material/dialog';
import { AccSearchTipComponent } from 'src/app/accountancy/custom-components/acc-search-tip/acc-search-tip.component';
import { ApiGetResponse } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { CashAdvanceService } from '../../../cash-advance/cash-advance-service/cash-advance.service';
import { DialogLiquidationToCashReturnComponent } from './liquidation-dialogs/dialog-liquidation-to-cash-return/dialog-liquidation-to-cash-return.component';
import { DialogLiquidationToReimbursementComponent } from './liquidation-dialogs/dialog-liquidation-to-reimbursement/dialog-liquidation-to-reimbursement.component';
import { ViewDetailsCaRequestLiquidationComponent } from '../../view-details-ca-request-liquidation/view-details-ca-request-liquidation.component';
import { AccTableComponent } from 'src/app/accountancy/custom-components/acc-table/acc-table.component';
import { ManageApproversService } from 'src/app/accountancy/home/account-management/manage-approvers/manage-approvers-service/manage-approvers.service';
import { DialogLiquidationNewComponent } from './liquidation-dialogs/dialog-liquidation-new/dialog-liquidation-new.component';
import { AccMessageDialogComponent, AccMessageDialogInterface } from 'src/app/accountancy/custom-components/acc-message-dialog/acc-message-dialog.component';

@Component({
  selector: 'app-tab-for-liquidation',
  templateUrl: './tab-for-liquidation.component.html',
  styleUrls: ['./tab-for-liquidation.component.scss']
})
export class TabForLiquidationComponent {

  public readonly pagination = new Pagination();
  public readonly liquidationList$ = new BehaviorSubject<LiquidationModifiedInterface[]>([]);

  private readonly _subsGetLiquidationList = this._getSubsGetLiquidationList();

  constructor(
    public loader: LoaderService,

    private _caS: CashAdvanceService,
    private _manageApprovalS: ManageApproversService,
    private _loginS: LoginService,
    private _liquidationS: LiquidationService,
    private _matDialog: MatDialog,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetLiquidationList.unsubscribe();
  }

  public onClosedStatus(liq: LiquidationModifiedInterface) {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._liquidationS.updateLiquidationStatus(liq.ID ?? -1, 'Closed').pipe(
        finalize(() => {
          this.pagination.reloader$.next();
          this.loader.finish();
          subs.unsubscribe();
        })
      ))
    ).subscribe();
  }

  public onDisapproveStatus(liq: LiquidationModifiedInterface) {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._liquidationS.updateLiquidationStatus(liq.ID ?? -1, 'Disapproved').pipe(
        finalize(() => {
          this.pagination.reloader$.next();
          this.loader.finish();
          subs.unsubscribe();
        })
      ))
    ).subscribe();
  }

  public onApproveStatus(liq: LiquidationModifiedInterface) {
    const validateNextStatus = (): string => {
      const possible = liq.NextStatus ?? 'No Status';
      return possible == 'For Closing' ? 'Closed' : possible;
    };

    const liquidation: LiquidationModifiedInterface = {
      ...liq, NextStatus: validateNextStatus()
    };

    const approveToNextStatus = () => {
      const subs: Subscription = of(null).pipe(
        tap(() => this.loader.request()),
        switchMap(() => this._liquidationS.updateLiquidationStatus(liquidation.ID ?? -1, liquidation.NextStatus ?? 'No Status').pipe(
          finalize(() => {
            this.pagination.reloader$.next();
            this.loader.finish();
            subs.unsubscribe();
          })
        )),
      ).subscribe();
    };

    if (!liquidation.Status) this._matDialog.open(DialogLiquidationNewComponent, { data: liquidation });
    else if (liquidation.NextStatus == 'For Reimbursement') this._matDialog.open(DialogLiquidationToReimbursementComponent, { data: liquidation });
    else if (liquidation.NextStatus == 'For Cash Refund') this._matDialog.open(DialogLiquidationToCashReturnComponent, { data: liquidation });
    else approveToNextStatus();
  }

  public dialogs = {
    openNewLiquidation: (liq?: LiquidationModifiedInterface) => this._matDialog.open(DialogLiquidationNewComponent, { data: liq }),
    // openForReimbursement: (liq: LiquidationModifiedInterface) => this._matDialog.open(DialogLiquidationToReimbursementComponent, { data: liq }),
    // openForCashReturn: (liq: LiquidationModifiedInterface) => this._matDialog.open(DialogLiquidationToCashReturnComponent, { data: liq }),
    openViewMore: (liq: LiquidationModifiedInterface) => this._matDialog.open(ViewDetailsCaRequestLiquidationComponent, { data: liq }),
    openSearch: () => this._matDialog.open(AccSearchTipComponent),
    openPrint: async (e: AccTableComponent) => await e.printTable(),
  }

  public transformStatus(status: string) {
    if (status == "Done") return 'success';
    if (status == "Disapproved") return 'danger';
    if (status == "Closed") return 'info';
    return 'warning';
  }

  private _getSubsGetLiquidationList() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query)),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => this.loader.request()),
      switchMap(([query, limit]) => this._liquidationS.getForLiquidation(this.pagination.start, limit, query, null, this._loginS.userData?.ID).pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body),
        // mergeMap(values => {
        //   if (!values) return of({ data: [], total_rows: 0 } as ApiGetResponse<LiquidationModifiedInterface>);

        //   const modifiedCaList = values?.data.map(v => of(v).pipe(
        //     switchMap(values => this._getNextStatus(values.Status).pipe(
        //       map(nextStatus => {
        //         const getNextStatus = () => {
        //           const nextProcess = this._getNextProcess(v.Total_Cost, v.CA_Request.Amount);

        //           const posible = (nextStatus == 'Done' ? (nextProcess == 'For Closing' ? 'Closed' : nextProcess) : nextStatus) ?? 'Invalid';
        //           return posible.includes('Invalid') ? 'For Closing' : posible;
        //         };


        //         return {
        //           ...values,
        //           NextStatus: getNextStatus(),
        //         } as LiquidationModifiedInterface;
        //       }
        //       ))
        //     )));

        //   if (!modifiedCaList.length) return of(values);

        //   return forkJoin(modifiedCaList).pipe(
        //     map(newList => ({ data: newList, total_rows: values.total_rows } as ApiGetResponse<LiquidationModifiedInterface>))
        //   );
        // }),

        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.liquidationList$.next(values?.data.map(v => ({
            ...v,
            checked: false,
            reloader$: this.pagination.reloader$,
            NextProcess: this._getNextProcess(v.Total_Cost, v.CA_Request.Amount),
          } as LiquidationModifiedInterface)) ?? []);
        }),
      ))
    ).subscribe();
  }

  private _getNextProcess(liqudationAmount?: number | null, caAmount?: number | null): string {
    if (liqudationAmount === null || liqudationAmount === undefined) return '';
    else if (caAmount === null || caAmount === undefined) return '';
    else if (liqudationAmount === caAmount) return 'For Closing';
    else if (liqudationAmount > caAmount) return 'For Reimbursement';
    else if (liqudationAmount < caAmount) return 'For Cash Refund';
    else return '';
  }

  // private _getNextStatus(currentStatus: string): Observable<string | undefined> {
  //   if (currentStatus == "Done" || currentStatus == "Disapproved") return of(undefined);
  //   return of(null).pipe(
  //     tap(() => this.loader.request()),
  //     switchMap(() => this._manageApprovalS.getApprovalLevels("Liquidation").pipe(
  //       finalize(() => this.loader.finish()),
  //       map(values => {
  //         if (!values) return 'No Status';

  //         const currentIndex = values?.findIndex(v => v.Status == currentStatus);
  //         if (currentIndex < 0) return values[0].Status;

  //         return (currentIndex + 1 == values.length) ? 'Done' : values[currentIndex + 1].Status;
  //       })
  //     ))
  //   )
  // }

}
