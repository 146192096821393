<app-acc-table [pagination]="pagination" [showBulkPrint]="true" (bulkPrint)="dialogs.openPrint($event)">
  <table class="custom-table">
    <thead class="text-xs uppercase">
      <tr *ngIf="loader.processing"></tr>
      <tr *ngIf="!loader.processing" class="relaive">
        <th class="bg-gray-50 text-sm whitespace-nowrap">Control Number</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Status</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Purpose</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Date of Use</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Transaction Date</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Due Date</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Peso Amount </th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Dollar Ammount</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Description</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Charge To </th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Remarks</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loader.processing">
        <td colspan="999">
          <app-acc-loader></app-acc-loader>
        </td>
      </tr>
      <ng-container *ngIf="!loader.processing">
        <ng-container *ngIf="caList$|async as caList">
          <tr *ngIf="!caList.length">
            <td colspan="999" class="bg-white sticky left-0">
              <div class="flex gap-2">
                No Data found...
                <div *ngIf="pagination.query" class="text-slate-500">
                  Can't find what your looking for?
                  <a (click)="dialogs.openSearch()" href="javascript:void(0);" class="text-blue-400 underline">
                    Search tips
                  </a>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of caList">
            <td> {{item.CntrlNumber||''}} </td>
            <td>
              <div class="pill pill-{{transformStatus|method:item.Status}} text-center">
                {{item.Status||'No Status'}}
              </div>
            </td>
            <td>
              <span class="whitespace-nowrap">{{item.Purpose||''}}</span>
            </td>
            <td> {{item.DateOfUse|date}} </td>
            <td> {{item.TransactionDate|date}} </td>
            <td> {{item.DueDate|date}} </td>
            <td> {{item.Amount|currency:'₱'}} </td>
            <td> {{item.DollarAmount|currency:'$'}} </td>
            <td> {{(item.Description||'')|strLimit:20}} </td>
            <td>
              <span class="whitespace-nowrap"> {{item.ChargeTo||''}} </span>
            </td>
            <td> {{item.Remarks||''}} </td>
            <td>
              <button (click)="dialogs.openViewMore(item)" class="button custom-button-sm">
                <i class="fal fa-eye"></i> View More
              </button>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</app-acc-table>