import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { PromptMessage } from 'src/app/accountancy/utilities/classes/promp-message.class';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { ManageApproverMatDialogRefData } from '../../manage-approver.component';
import { ManageApproverService } from '../../manage-approver-service/manage-approver.service';
import { UserListService } from 'src/app/accountancy/home/account-management/user-list/user-list-service/user-list.service';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/accountancy/utilities/validators/custom-validator';

@Component({
  selector: 'app-edit-manage-approver',
  templateUrl: './edit-manage-approver.component.html',
  styleUrls: ['./edit-manage-approver.component.scss']
})
export class EditManageApproverComponent {

  public readonly prompt = new PromptMessage();

  public readonly fgApproverLevel = this._newFgApprovalLevel();
  public readonly allUserListContainer$: Observable<string[] | undefined>[] = [];

  constructor(
    public loader: LoaderService,

    @Inject(MAT_DIALOG_DATA) private data: ManageApproverMatDialogRefData,
    private _cdr: ChangeDetectorRef,
    private _manageApproverS: ManageApproverService,
    private _dialogRef: MatDialogRef<EditManageApproverComponent>,
    private _userListS: UserListService,
  ) {

    this._patchValueToEditor();
  }

  public onSubmit() {
    const formData = this.fgApproverLevel.value;

    if (Helper.getInvalidControls(this.fgApproverLevel, true).length) {
      this.prompt.set(Helper.errorMessage.requiredFields, 'alert alert-danger mb-4');
      return;
    }

    if (formData.Approvers?.length == 0) {
      this.prompt.set('Please add at least one Approver', 'alert alert-danger mb-4');
      return;
    }

    this.prompt.clear();

    const body = {
      Status: formData.Status,
      Approvers: formData.Approvers?.map(v => Helper.string.extractStringInParentheses(v)).map(v => Helper.string.formatId.toNumber(v)),
    };

    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._manageApproverS.updateApprovalLevel(this.data.ApprovalLevel?.ID ?? -1, body).pipe(
        finalize(() => {
          subs.unsubscribe();
          this.loader.finish();
        })
      ))
    ).subscribe(Helper.api.handleStatusResponse({
      prompt: this.prompt, successMessage: 'Successfully Saved!', onSuccess: () => {
        this.data.reloader$?.next();
        this.fgApproverLevel.reset();
        this._dialogRef.close();
      },
    }));
  }

  public onClose() {
    this._dialogRef.close();
  }

  public getFaApprover() {
    return this.fgApproverLevel.get('Approvers') as FormArray<FormControl<string | undefined | null>>;
  }

  public addApprover(value?: string | null) {
    const fc = this._newFcApprover(value);
    const userList$: Observable<string[] | undefined> = fc.valueChanges.pipe(
      switchMap(query => this._getUserList(
        this.getFaApprover().controls.filter(ac => ac.valid).map(v => v.value),
      ).pipe(
        map(values => values?.filter(v => query ? Helper.string.trimLowerCase(v).includes(Helper.string.trimLowerCase(query)) : true)),
      ))
    );

    this.allUserListContainer$.push(userList$);
    this.getFaApprover().push(fc);
  }

  public removeApproverAt(index: number) {
    this.getFaApprover().removeAt(index);
    this.allUserListContainer$.splice(index, 1);
  }

  private _newFgApprovalLevel() {
    return new FormGroup({
      Status: new FormControl<string | undefined | null>('', [Validators.required]),
      Approvers: new FormArray<FormControl<string | undefined | null>>([]),
    });
  }

  private _newFcApprover(value?: string | null) {
    const fc = new FormControl<string | undefined | null>(value, {
      validators: [Validators.required],
      asyncValidators: [CustomValidator.async.existIn(this._getUserList())],
    });

    return fc;
  }

  private _getUserList(exclude?: (string | undefined | null)[] | null) {
    return of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._userListS.getUserlist().pipe(
        finalize(() => this.loader.finish()),
        map(values => values?.data),
        map(values => values?.map(v => `(${Helper.string.formatId.toId(v.ID, 'AGF', 5)}) ${v.LastName}, ${v.FirstName}`)),
        map(values => values?.filter(v => exclude ? !exclude.includes(v) : true)),
      ))
    );
  }

  private _patchValueToEditor() {
    const { ApprovalLevel } = this.data;
    if (!ApprovalLevel) return;

    this.fgApproverLevel.patchValue({ Status: ApprovalLevel.Status }, { emitEvent: false });

    ApprovalLevel.Approvers.forEach(v => {
      this.addApprover(`(${Helper.string.formatId.toId(v.ID, 'AGF', 5)}) ${v.LastName}, ${v.FirstName}`);
    });
  }

}
