<div class="card md:min-w-[500px]">

  <div class="card-header">
    <i class="fal fa-exclamation-triangle text-themeRed font-bold text-3xl"></i> Delete Action
  </div>

  <div class="card-body">
    <div class="text-center my-5">
      <p class="mb-4">Are you sure you want to delete this data?</p>
      <p class="text-red-600">{{data.ApprovalLevel?.Status}}</p>
    </div>
  </div>

  <div class="card-footer">

    <div class="flex justify-between items-center mb-4">
      <button [disabled]="loader.processing" (click)="onCancel()" class="custom-button custom-button-primary">
        Cancel
      </button>
      <button [disabled]="loader.processing" (click)="onDelete()" class="custom-button custom-button-primary-filled">
        Delete
      </button>
    </div>

    <div *ngIf="prompt.message" class="{{prompt.type}}">{{prompt.message}}</div>

  </div>

</div>