import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccConfirmationComponent, AccConfirmationInterface } from '../acc-confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class AccConfirmationService {

  constructor(
    private _matDialog: MatDialog,
  ) { }

  public open(params: AccConfirmationInterface, disableClose = false) {
    this._matDialog.open(AccConfirmationComponent, { data: params, disableClose });
  }
}
