import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map, of, switchMap } from 'rxjs';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ApiRoutes } from 'src/app/accountancy/utilities/classes/api-routes.class';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';
import { ApiGetResponse, ApiPostResponse } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { UserInterface } from 'src/app/accountancy/utilities/interfaces/user-data.interface';
import { CommonService } from 'src/app/accountancy/utilities/services/common/common.service';
import { HttpApiService } from 'src/app/accountancy/utilities/services/http-api/http-api.service';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class UserListService {

  constructor(
    private _commonS: CommonService,
    private api: HttpApiService,
    private loginS: LoginService,
  ) { }

  public newUser(fg: FormGroup) {
    const formData = fg.value;
    const formBody = {
      ...formData,
      Access: [],
      CreatedBy: this.loginS.userData?.ID,
    }

    return this.api.sendPostRequest<HttpResponse<ApiPostResponse<any>>>(`${ApiRoutes.users}`, formBody, this.loginS.getTokenHeader).pipe(
      map(values => values?.body),
    )
  }

  public getUserlist(start?: number | null, limit?: number | null, query?: string | null) {
    return this.api.sendGetRequest<HttpResponse<ApiGetResponse<UserInterface>>>(
      `${ApiRoutes.users}?${start != undefined ? 'Start=' + start : ''}${limit ? '&Limit=' + limit : ''}${query ? '&Search=' + query : ''}`,
      this.loginS.getTokenHeader
    ).pipe(
      map(values => values?.body)
    );
  }

}
