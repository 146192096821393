<div class="card md:min-w-[500px]">
  <div class="card-header">Reimbursement</div>
  <div class="card-body">
    <mat-dialog-content>
      <div *ngIf="loader.processing" class="alert alert-info">
        We are handling your request. Please wait.
      </div>
      <ng-container *ngIf="!loader.processing">
        <div class="mb-4">
          <div class="font-bold">Control Number</div>
          <div *ngIf="data.CntrlNumber" class="ml-2">{{data.CntrlNumber}}</div>
          <div *ngIf="!data.CntrlNumber" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Proof
            <span *ngIf="data.Proof" class="text-sm text-cyan-500">
              <a class="link" [href]="downloadLink$|async" target="_blank">(Click to view)</a>
            </span>
          </div>
          <div *ngIf="data.Proof" class="ml-2">
            {{data.Proof}}
          </div>
          <div *ngIf="!data.Proof" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Purpose</div>
          <div *ngIf="data.Purpose" class="ml-2">{{data.Purpose}}</div>
          <div *ngIf="!data.Purpose" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Product</div>
          <div *ngIf="data.Product" class="ml-2">{{data.Product}}</div>
          <div *ngIf="!data.Product" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Covered Date</div>
          <div *ngIf="data.CoveredDateFrom" class="ml-2">From: {{data.CoveredDateFrom}}</div>
          <div *ngIf="!data.CoveredDateFrom" class="ml-2 text-zinc-500">No data</div>
          <div *ngIf="data.CoveredDateTo" class="ml-2">To: {{data.CoveredDateTo}}</div>
          <div *ngIf="!data.CoveredDateTo" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Transaction Date</div>
          <div *ngIf="data.TransactionDate" class="ml-2">{{data.TransactionDate}}</div>
          <div *ngIf="!data.TransactionDate" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Due Date</div>
          <div *ngIf="data.DueDate" class="ml-2">{{data.DueDate}}</div>
          <div *ngIf="!data.DueDate" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Amount</div>
          <div *ngIf="data.ReimbursementAmount" class="ml-2">{{data.ReimbursementAmount}}</div>
          <div *ngIf="!data.ReimbursementAmount" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Dollar Amount</div>
          <div *ngIf="data.DollarAmount" class="ml-2">{{data.DollarAmount}}</div>
          <div *ngIf="!data.DollarAmount" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Description</div>
          <div *ngIf="data.Description" class="ml-2">{{data.Description}}</div>
          <div *ngIf="!data.Description" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Remarks</div>
          <div *ngIf="data.Remarks" class="ml-2">{{data.Remarks}}</div>
          <div *ngIf="!data.Remarks" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Requested By</div>
          <div *ngIf="data.RequestedBy" class="ml-2">
            (AGF-{{data.RequestedBy.ID|padStart:5:'0'}})
            {{data.RequestedBy.Lastname}},
            {{data.RequestedBy.Firstname}}
          </div>
          <div *ngIf="!data.RequestedBy" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Requested at</div>
          <div *ngIf="data.created_at" class="ml-2">{{data.created_at}}</div>
          <div *ngIf="!data.created_at" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Updated By</div>
          <div *ngIf="data.UpdatedBy" class="ml-2">
            (AGF-{{data.UpdatedBy.ID|padStart:5:'0'}})
            {{data.UpdatedBy.Lastname}},
            {{data.UpdatedBy.Firstname}}
          </div>
          <div *ngIf="!data.UpdatedBy" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Updated At</div>
          <div *ngIf="data.updated_at" class="ml-2">{{data.updated_at}}</div>
          <div *ngIf="!data.updated_at" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div *ngIf="data.history?.length" class="mb-4">
          <div class="border rounded-sm shadow-sm p-4">
            <div class="font-bold text-lg mb-3">History</div>
            <div *ngFor="let history of data.history" class="bg-zinc-100 p-2 mb-2 border text-sm">
              <div class="mb-2">
                <div class="font-bold">Created by</div>
                <div *ngIf="history.CreatedBy" class="ml-2">
                  (AGF-{{history.CreatedBy.ID|padStart:5:'0'}})
                  {{history.CreatedBy.LastName}},
                  {{history.CreatedBy.FirstName}}
                </div>
                <div *ngIf="!history.CreatedBy" class="ml-2 text-zinc-500">No data</div>
              </div>
              <div class="mb-2">
                <div class="font-bold">Status</div>
                <div *ngIf="history.Status" class="ml-2">{{history.Status}}</div>
                <div *ngIf="!history.Status" class="ml-2 text-zinc-500">No data</div>
              </div>
              <div class="mb-2">
                <div class="font-bold">Type</div>
                <div *ngIf="history.Type" class="ml-2">{{history.Type}}</div>
                <div *ngIf="!history.Type" class="ml-2 text-zinc-500">No data</div>
              </div>
              <div class="mb-2">
                <div class="font-bold">Created at</div>
                <div *ngIf="history.created_at" class="ml-2">{{history.created_at|formatDateTime}}</div>
                <div *ngIf="!history.created_at" class="ml-2 text-zinc-500">No data</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-dialog-content>
  </div>
  <div class="card-footer">
    <div class="form-submit">
      <button (click)="onClose()" class="custom-button">Close</button>
    </div>
  </div>
</div>