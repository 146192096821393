<div class="card md:min-w-[500px]">
  <div class="card-header">{{data.title ?? 'Remarks'}}</div>
  <div class="card-body">
    <label for="remarks-input {{data.messageClass}}">(Optional) {{data.message}}</label>
    <input [(ngModel)]="remarksValue" type="text" class="form-control" [placeholder]="data.placeholder??''"
      id="remarks-input">
  </div>
  <div class="card-footer">
    <div class="form-submit">
      <button (click)="onSubmit()" type="button" class="custom-button custom-button-primary">Continue</button>
      <button (click)="onCancel()" type="button" class="custom-button">Cancel</button>
    </div>
  </div>
</div>