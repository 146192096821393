<div class="flex justify-between items-center mb-4">
  <div class="text-xl font-semibold">New Cash Advance Transaction</div>
</div>

<form [formGroup]="fgTR" (submit)="onSubmit()" class="grid grid-cols-12 gap-4">

  <div class="col-span-12">
    <div class="mb-4">
      <span class="form-label">Purpose <span class="text-red-400">*</span></span>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 sm:col-span-4">
          <mat-select formControlName="Purpose1" class="form-control">
            <mat-option value="Travel">Travel</mat-option>
            <mat-option value="Non-Travel Related">Non-Travel Related</mat-option>
          </mat-select>
        </div>
        <div class="col-span-12 sm:col-span-4">
          <mat-select formControlName="Purpose2" class="form-control">
            <mat-option value="Vehicle Repairs">Vehicle Repairs</mat-option>
            <mat-option value="Others">Others</mat-option>
          </mat-select>
        </div>
        <div class="col-span-12 sm:col-span-4">
          <input *ngIf="fcPurpose2isOthers$|async" formControlName="Purpose3" type="text" placeholder="Please Specify"
            class="form-control">
        </div>
      </div>
      <div *ngIf="getPuposeIsInvalid" class="text-red-500 text-sm">
        * Purpose is required.
      </div>
    </div>
  </div>

  <div class="col-span-12 sm:col-span-4">
    <div class="mb-4">
      <span class="form-label">Date of Use <span class="text-red-400">*</span></span>
      <div class="form-group">
        <input formControlName="DateOfUse" matInput [matDatepicker]="pickerDateOfUse" type="text"
          class="form-group-input">
        <div class="form-group-label">
          <button (click)="pickerDateOfUse.open()" type="button" class="outline-none"><i
              class="fal fa-calendar"></i></button>
          <mat-datepicker #pickerDateOfUse></mat-datepicker>
        </div>
      </div>
      <div *ngIf="fgTR.get('DateOfUse')?.invalid && fgTR.get('DateOfUse')?.touched" class="text-red-500 text-sm">
        * Date of Use is required.
      </div>
    </div>
  </div>

  <div class="col-span-12 sm:col-span-4">
    <div class="mb-4">
      <span class="form-label">Tran. Date <span class="text-red-400">*</span></span>
      <div class="form-group">
        <input formControlName="TransactionDate" matInput [matDatepicker]="pickerTransactionDate" type="text"
          class="form-group-input">
        <div class="form-group-label">
          <button (click)="pickerTransactionDate.open()" type="button" class="outline-none"><i
              class="fal fa-calendar"></i></button>
          <mat-datepicker #pickerTransactionDate></mat-datepicker>
        </div>
      </div>
      <div *ngIf="fgTR.get('TransactionDate')?.invalid && fgTR.get('TransactionDate')?.touched" class="text-red-500 text-sm">
        * Tran. Date is required.
      </div>
    </div>
  </div>

  <div class="col-span-12 sm:col-span-4">
    <div class="mb-4">
      <span class="form-label">Due Date <span class="text-red-400">*</span></span>
      <div class="form-group">
        <input formControlName="DueDate" matInput [matDatepicker]="pickerDueDate" type="text" class="form-group-input"
          [min]="Helper.date.format(Helper.date.addDay(fgTR.get('TransactionDate')?.value, 30) , 1, '-')">
        <div class="form-group-label">
          <button (click)="pickerDueDate.open()" type="button" class="outline-none"><i
              class="fal fa-calendar"></i></button>
          <mat-datepicker #pickerDueDate></mat-datepicker>
        </div>
      </div>
      <div *ngIf="fgTR.get('DueDate')?.invalid && fgTR.get('DueDate')?.touched" class="text-red-500 text-sm">
        * Due Date is required.
      </div>
    </div>
  </div>

  <div class="col-span-12 ">
    <div class="mb-4">
      <span class="form-label">CA Amount <span class="text-red-400">*</span></span>
      <div class="form-group">
        <div class="form-group-label">₱</div>
        <input formControlName="Amount" type="text" class="form-group-input" placeholder="0.00">
      </div>
      <div *ngIf="fgTR.get('Amount')?.invalid && fgTR.get('Amount')?.touched" class="text-red-500">
        * Amount is required.
      </div>
      <div *ngIf="fgTR.get('Amount')?.errors?.['numeric']" class="text-red-500 text-sm">
        * Invalid amount value. Please remove any non-numeric characters.
      </div>
    </div>
  </div>

  <div class="col-span-12">
    <div class="mb-4">
      <span class="form-label">CA Description <span class="text-red-400">*</span></span>
      <textarea formControlName="Description" class="form-control"></textarea>
      <div *ngIf="fgTR.get('Description')?.invalid && fgTR.get('Description')?.touched" class="text-red-500 text-sm">
        * Description is required.
      </div>
    </div>
  </div>

  <div class="col-span-12">
    <div class="mb-4">
      <span class="form-label">Charge To <span class="text-red-400">*</span></span>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 sm:col-span-4">
          <input formControlName="ChargeTo1" placeholder="Select department" type="text"
            [matAutocomplete]="auto_ChargeTo1" class="form-control">
          <mat-autocomplete #auto_ChargeTo1>
            <mat-option *ngIf="loader.processing" disabled="">Searching...</mat-option>
            <ng-container *ngIf="departmentList$|async as departmentList">
              <mat-option *ngIf="!departmentList.length" disabled="">Nothing found...</mat-option>
              <mat-option *ngFor="let department of departmentList" [value]="department.Name">
                {{department.Name}}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>
        <div class="col-span-12 sm:col-span-4">
          <mat-select formControlName="ChargeTo2" class="form-control">
            <mat-option value="No Detachment">No Detachment</mat-option>
            <mat-option value="With Detachment">With Detachment</mat-option>
          </mat-select>
        </div>
        <div class="col-span-12 sm:col-span-4">
          <ng-container *ngIf="fcChargeTo2isDetachment$|async">
            <input formControlName="ChargeTo3" placeholder="Select detachment" type="text"
              [matAutocomplete]="auto_ChargeTo3" class="form-control">
            <mat-autocomplete #auto_ChargeTo3>
              <mat-option *ngIf="loader.processing" disabled="">Searching...</mat-option>
              <ng-container *ngIf="detachmentList$|async as detachmentList">
                <mat-option *ngIf="!detachmentList.length" disabled="">Nothing found...</mat-option>
                <mat-option *ngFor="let detachment of detachmentList" [value]="detachment.Detachment">
                  {{detachment.Detachment}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </ng-container>
        </div>
      </div>
      <div *ngIf="getChargeToIsInvalid" class="text-red-500">
        <div *ngIf="fgTR.get('ChargeTo1')?.errors?.['required']" class="text-sm">
          * Charge to department is required.
        </div>
        <div *ngIf="fgTR.get('ChargeTo1')?.errors?.['existIn']" class="text-sm">
          * Charge to department does not exist.
        </div>
        <div *ngIf="fgTR.get('ChargeTo3')?.errors?.['required']" class="text-sm">
          * Charge to detachment is required.
        </div>
        <div *ngIf="fgTR.get('ChargeTo3')?.errors?.['existIn']" class="text-sm">
          * Charge to detachment does not exist.
        </div>
      </div>
    </div>
  </div>

  <div class="col-span-12">
    <div class="mb-4">
      <span class="form-label">Remarks </span>
      <textarea formControlName="Remarks" class="form-control"></textarea>
    </div>
  </div>

  <div class="col-span-12">
    <div class="alert alert-danger mb-4">
      <div class="font-semibold mb-4"><i class="fa-light fa-brake-warning"></i> Warning</div>
      Once submitted, values cannot be modified or altered. Please verify all fields before submitting.
    </div>
    <div *ngIf="loader.processing" class="alert alert-info mb-4">
      We are handling your request. Please wait.
    </div>
    <div *ngIf="prompt.message" class="{{prompt.type}}">{{prompt.message}}</div>
  </div>

  <div class="col-span-12">
    <div class="form-submit mb-4">
      <button [disabled]="loader.processing" type="submit" class="custom-button custom-button-primary-filled">
        <i class="fal fa-plus-circle"></i> Save Transaction
      </button>
    </div>
  </div>

</form>