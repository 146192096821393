import { ChangeDetectorRef, Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpApiService } from '../../../utilities/services/http-api/http-api.service';
import { LocalStorageApiService } from '../../../utilities/services/local-storage-api/local-storage-api.service';
import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, finalize, map, Observable, of, skip, Subscription, switchMap, tap, UnsubscriptionError } from 'rxjs';
import { LoaderService } from '../../../utilities/services/loader/loader.service';
import { PromptMessage } from '../../../utilities/classes/promp-message.class';
import { Router } from '@angular/router';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { ApiRoutes } from 'src/app/accountancy/utilities/classes/api-routes.class';
import { ApiGetResponse } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { UserTokenInterface } from 'src/app/accountancy/utilities/interfaces/user-data.interface';
import { AccMessageDialogService } from 'src/app/accountancy/custom-components/acc-message-dialog/acc-message-dialog-service/acc-message-dialog.service';
import { CommonService } from 'src/app/accountancy/utilities/services/common/common.service';
import { UserAccess } from 'src/app/accountancy/utilities/classes/user-access.class';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public get userData() {
    return this.localS.object.get<UserTokenInterface | undefined | null>('data');
  }

  public get userIsLogedIn() {
    const auth = !!this.localS.string.get('auth');
    const data = !!this.localS.string.get('data');
    return auth && data;
  }

  public get loginTime() {
    const loginTime = this.localS.string.get('loginTime');
    return loginTime ? new Date(loginTime) : null;
  }

  public get isSessionExpired() {

    // If in development mode, session will never expire
    if (!environment.production) return false;

    const loginTime = this.loginTime;

    if (!loginTime) return true;

    const timeNow = new Date().getTime();
    const loginTimeLimit = loginTime.getTime() + (1000 * 60 * 30);
    const timeRemaining = loginTimeLimit - timeNow;

    if (!environment.production) console.log(`Session Time Remaining: ${(timeRemaining / 1000 / 60).toFixed(2)} min.`);

    return timeRemaining < 0
  }

  public get navigationCount() {
    return this._navigationCount.getValue();
  }

  public get getToken() {
    return this.localS.string.get('auth');
  }

  public get getTokenHeader() {
    return new HttpHeaders({ Authorization: `Bearer ${this.getToken ?? ''}` });
  }

  private routes = Routes;

  private _navigationCount = new BehaviorSubject(0);

  constructor(
    private loader: LoaderService,
    private api: HttpApiService,
    private localS: LocalStorageApiService,
    private router: Router,
    public commonS: CommonService,
  ) { }

  public updateSavedUserData(then?: (user?: UserTokenInterface | null) => void) {
    const subs: Subscription = this._getUser$.pipe(
      tap(user => { if (then) then(user); }),
      finalize(() => {
        subs.unsubscribe();
      }),
    ).subscribe();
  }

  public login(username?: string | null, password?: string | null, prompt?: PromptMessage) {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this.api.sendGetRequest<HttpResponse<null>>(`${ApiRoutes.loginUser}?username=${username}&password=${password}`).pipe(
        map(values => values?.headers.get('x-amzn-Remapped-Authorization')),
        tap(auth => this._saveAuth(auth)),
        switchMap(auth => this._getUser$.pipe(
          finalize(() => subs.unsubscribe())
        )),
        finalize(() => this.loader.finish()),
      ))
    ).subscribe({
      complete: () => {
        prompt?.set('Successfully Logged In!', 'success');
        this.router.navigate([this.routes.dashboard]);
      },
      error: (err) => {
        Helper.handleError(err, prompt);
      },
    });
  }

  public logout() {
    this.localS.remove('auth');
    this.localS.remove('loginTime');
    this.localS.remove('navigationCount');
    this.router.navigate([this.routes.login]);
  }

  public addNavigationCount() {
    let localNavCount = this.localS.number.get('navigationCount');

    if (!localNavCount) {
      localNavCount = 0;
      this.localS.number.set('navigationCount', localNavCount);
    }
    this._navigationCount.next(localNavCount + 1);
    this.localS.number.set('navigationCount', this.navigationCount);
  }

  public can(accessModule?: string | null) {
    if (accessModule === null) return true;
    return UserAccess.userCan(accessModule, this.userData?.Access ?? []);
  }

  private _saveAuth(auth?: string | null) {
    const dateToday = new Date();
    const loginTime = `${dateToday.toLocaleDateString()} ${dateToday.toLocaleTimeString()}`

    this.localS.string.set('loginTime', loginTime);
    if (auth) this.localS.string.set('auth', auth.split(' ')[1]);
  }

  private _getUser$: Observable<UserTokenInterface | null> = of(null).pipe(
    tap(() => this.loader.request()),
    switchMap(() => {
      if (!this.getToken) return of(null).pipe(
        finalize(() => this.loader.finish()),
      );
      else return this.api.sendGetRequest<HttpResponse<{ status: number, data: UserTokenInterface }>>(
        `${ApiRoutes.user}?token=${this.getToken}`,
        this.getTokenHeader
      ).pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body?.data),
        map(values => ({ ...values, FormattedID: Helper.users.formatUID(values?.ID) } as UserTokenInterface)),
        tap(user => this.localS.object.set('data', user)),
      );
    })
  );

}
