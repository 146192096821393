import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CashAdvanceComponent } from './cash-advance/cash-advance.component';
import { LiquidationComponent } from './liquidation/liquidation.component';
import { DisbursementComponent } from './disbursement.component';
import { ReimbursementComponent } from './reimbursement/reimbursement.component';

const routes: Routes = [
  { path: '', component: DisbursementComponent },
  { path: 'cash-advance', component: CashAdvanceComponent },
  { path: 'reimbursement', component: ReimbursementComponent },
  { path: 'liquidation', component: LiquidationComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DisbursementRoutingModule { }
