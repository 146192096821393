<app-acc-table [pagination]="pagination" tableTitle="Cash advance list" [showTitle]="true" [showBulkPrint]="true"
  [showFilter]="true" (bulkPrint)="dialogs.openPrint($event)">
  <table class="custom-table custom-table-default">
    <thead class="text-xs uppercase">
      <tr *ngIf="loader.processing"></tr>
      <tr *ngIf="!loader.processing" class="relaive">
        <th>Control Number</th>
        <th>Status</th>
        <th>Purpose</th>
        <th>Date of Use</th>
        <th>Transaction Date</th>
        <th>Due Date</th>
        <th>Peso Amount </th>
        <th>Dollar Ammount</th>
        <th>Description</th>
        <th>Charge To </th>
        <th>Remarks</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loader.processing">
        <td colspan="999">
          <app-acc-loader></app-acc-loader>
        </td>
      </tr>
      <ng-container *ngIf="!loader.processing">
        <ng-container *ngIf="caList$|async as caList">
          <tr *ngIf="!caList.length&&!loader.processing">
            <td colspan="999">
              <div class="flex gap-2">
                No Data found...
                <div *ngIf="pagination.query" class="text-slate-500">
                  Can't find what your looking for?
                  <a (click)="dialogs.openSearch()" href="javascript:void(0);" class="text-blue-400 underline">
                    Search tips
                  </a>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of caList">
            <td class="whitespace-nowrap">{{item.CntrlNumber||''}} </td>
            <td class="whitespace-nowrap">
              <span class="badge badge-{{transformStatus|method:item.Status}} whitespace-nowrap">
                {{item.Status||'No Status'}}
              </span>
            </td>
            <td class="whitespace-nowrap">{{item.Purpose||''}}</td>
            <td class="whitespace-nowrap">{{item.DateOfUse|date}} </td>
            <td class="whitespace-nowrap">{{item.TransactionDate|date}} </td>
            <td class="whitespace-nowrap">{{item.DueDate|date}} </td>
            <td class="whitespace-nowrap">{{item.Amount|currency:'₱ '}} </td>
            <td class="whitespace-nowrap">{{item.DollarAmount|currency:'$'}} </td>
            <td class="whitespace-nowrap">{{(item.Description||'')|strLimit:50}} </td>
            <td class="whitespace-nowrap">
              <span class="whitespace-nowrap">{{item.ChargeTo||''}} </span>
            </td>
            <td class="whitespace-nowrap">{{item.Remarks||''}} </td>
            <td class="print-data-false">
              <button (click)="dialogs.openViewMore(item)" class="button custom-button-sm">
                <i class="fal fa-eye"></i> View More
              </button>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</app-acc-table>