import { Component } from '@angular/core';
import { LiquidationService } from '../../liquidation-service/liquidation.service';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap, tap } from 'rxjs';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { Pagination } from 'src/app/accountancy/utilities/classes/pagination.class';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { MatDialog } from '@angular/material/dialog';
import { AccSearchTipComponent } from 'src/app/accountancy/custom-components/acc-search-tip/acc-search-tip.component';
import { AccTableComponent } from 'src/app/accountancy/custom-components/acc-table/acc-table.component';
import { DialogLiquidationNewComponent } from './liquidation-dialogs/dialog-liquidation-new/dialog-liquidation-new.component';
import { LiquidationChangeStatusComponent } from './liquidation-change-status/liquidation-change-status.component';

@Component({
  selector: 'app-tab-for-liquidation',
  templateUrl: './tab-for-liquidation.component.html',
  styleUrls: ['./tab-for-liquidation.component.scss']
})
export class TabForLiquidationComponent {

  public readonly pagination = new Pagination();
  public readonly liquidationList$ = new BehaviorSubject<LiquidationModifiedInterface[]>([]);

  private readonly _subsGetLiquidationList = this._getSubsGetLiquidationList();

  public userData = this._loginS.userData;

  constructor(
    public loader: LoaderService,

    private _loginS: LoginService,
    private _liquidationS: LiquidationService,
    private _matDialog: MatDialog,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetLiquidationList.unsubscribe();
  }

  public dialogs = {
    openNewLiquidation: (liq?: LiquidationModifiedInterface) => this._matDialog.open(DialogLiquidationNewComponent, { data: liq }),
    openViewMore: (liq: LiquidationModifiedInterface) => this._matDialog.open(LiquidationChangeStatusComponent, { data: liq }),
    openSearch: () => this._matDialog.open(AccSearchTipComponent),
    openPrint: async (e: AccTableComponent) => await e.printTable(),
  }

  public transformStatus(status: string) {
    if (status == "Done") return 'green';
    if (status == "Disapproved") return 'red';
    if (status == "Closed") return 'indigo';
    return 'yellow';
  }

  private _getSubsGetLiquidationList() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query), debounceTime(500), distinctUntilChanged()),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => this.loader.request()),
      switchMap(([query, limit]) => this._liquidationS.getForLiquidation(this.pagination.start, limit, query, null, this._loginS.userData?.ID).pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body),

        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.liquidationList$.next(values?.data.map(v => ({
            ...v,
            checked: false,
            NextStatus: (v.NextStatus == "For Reimbursement" || v.NextStatus == "For Cash Refund" ? "Done" : v.NextStatus),
            reloader$: this.pagination.reloader$,
            NextProcess: this._getNextProcess(v.Total_Cost, v.CA_Request.Amount),
          } as LiquidationModifiedInterface)) ?? []);
        }),
      ))
    ).subscribe();
  }

  private _getNextProcess(liqudationAmount?: number | null, caAmount?: number | null): string {
    if (liqudationAmount === null || liqudationAmount === undefined) return '';
    else if (caAmount === null || caAmount === undefined) return '';
    else if (liqudationAmount === caAmount) return 'For Closing';
    else if (liqudationAmount > caAmount) return 'For Reimbursement';
    else if (liqudationAmount < caAmount) return 'For Cash Refund';
    else return '';
  }

}
