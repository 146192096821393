import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, of } from 'rxjs';
import { AccMessageDialogInterface } from 'src/app/accountancy/custom-components/acc-message-dialog/acc-message-dialog.component';
import { environment } from 'src/environments/environment';
import { Helper } from '../../classes/helper.class';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { AccMessageDialogService } from 'src/app/accountancy/custom-components/acc-message-dialog/acc-message-dialog-service/acc-message-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class HttpApiService {

  private readonly providerPath = environment.baseURL;
  private readonly hrisPath = environment.hrisURL;
  private dialogErrorCount = 0;

  constructor(
    private http: HttpClient,
    private _msgDialog: AccMessageDialogService,
  ) {

    this.dialogErrorCount = 0;
  }

  public raw = {
    sendPutRequest: <T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.put<T>(path, body, requestOptions).pipe(
        catchError(err => {
          if (this.dialogErrorCount < 1) {
            this._msgDialog.open({
              title: 'An error occurred.',
              message: Helper.handleError(err),
              messageType: 'text-red-500',
              onClose: () => this.dialogErrorCount = 0,
            }, true);
            this.dialogErrorCount += 1;
          }
          throw err;
        })
      );
    },
    sendGetRequest: <T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        params: params,
        observe: "response" as 'body',
      };
      return this.http.get<T>(path, requestOptions).pipe(
        catchError(err => {
          if (this.dialogErrorCount < 1) {
            this._msgDialog.open({
              title: 'An error occurred.',
              message: Helper.handleError(err),
              messageType: 'text-red-500',
              onClose: () => this.dialogErrorCount = 0,
            }, true);
            this.dialogErrorCount += 1;
          }
          throw err;
        })
      );
    },
    sendPostRequest: <T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.post<T>(path, body, requestOptions).pipe(
        catchError(err => {
          if (this.dialogErrorCount < 1) {
            this._msgDialog.open({
              title: 'An error occurred.',
              message: Helper.handleError(err),
              messageType: 'text-red-500',
              onClose: () => this.dialogErrorCount = 0,
            }, true);
            this.dialogErrorCount += 1;
          }
          throw err;
        })
      );
    },
    sendPatchRequest: <T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.patch<T>(path, body, requestOptions).pipe(
        catchError(err => {
          if (this.dialogErrorCount < 1) {
            this._msgDialog.open({
              title: 'An error occurred.',
              message: Helper.handleError(err),
              messageType: 'text-red-500',
              onClose: () => this.dialogErrorCount = 0,
            }, true);
            this.dialogErrorCount += 1;
          }
          throw err;
        })
      );
    },
    sendDeleteRequest: <T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) => {
      const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
      };
      return this.http.delete<T>(path, requestOptions).pipe(
        catchError(err => {
          if (this.dialogErrorCount < 1) {
            this._msgDialog.open({
              title: 'An error occurred.',
              message: Helper.handleError(err),
              messageType: 'text-red-500',
              onClose: () => this.dialogErrorCount = 0,
            }, true);
            this.dialogErrorCount += 1;
          }
          throw err;
        })
      );
    },
  }



  public hris = {

    sendPutRequest: <T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) =>
      this.raw.sendPutRequest<T>(`${this.hrisPath}${path}`, body, headers, params),

    sendGetRequest: <T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) =>
      this.raw.sendGetRequest<T>(`${this.hrisPath}${path}`, headers, params),

    sendPostRequest: <T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) =>
      this.raw.sendPostRequest<T>(`${this.hrisPath}${path}`, body, headers, params),

    sendPatchRequest: <T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) =>
      this.raw.sendPatchRequest<T>(`${this.hrisPath}${path}`, body, headers, params),

    sendDeleteRequest: <T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) =>
      this.raw.sendDeleteRequest<T>(`${this.hrisPath}${path}`, headers, params),
  }

  sendGetRequest<T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    return this.raw.sendGetRequest<T>(`${this.providerPath}${path}`, headers, params);
  }

  sendPostRequest<T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    return this.raw.sendPostRequest<T>(`${this.providerPath}${path}`, body, headers, params);
  }

  sendPatchRequest<T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    return this.raw.sendPatchRequest<T>(`${this.providerPath}${path}`, body, headers, params);
  }

  sendPutRequest<T>(path: string, body: any, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    return this.raw.sendPutRequest<T>(`${this.providerPath}${path}`, body, headers, params);
  }

  sendDeleteRequest<T>(path: string, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams()) {
    return this.raw.sendDeleteRequest<T>(`${this.providerPath}${path}`, headers, params);
  }

}
