<div class="mb-4">
  <h5 class="text-[20px] text-heading font-bold mb-1 leading-none">Reimbursement</h5>
  <ul>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2">
      <a [routerLink]="routes.dashboard">Home</a>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2 translate-y-0">
      <i class="far fa-chevron-right"></i>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2">
      <a [routerLink]="routes.disbursement">Disbursement</a>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2 translate-y-0">
      <i class="far fa-chevron-right"></i>
    </li>
    <li class="text-[14px] font-semibold font-normal inline-block mr-2">
      Reimbursement
    </li>
  </ul>
</div>

<div class="bg-white p-4 mb-10">
  <div class="border-b border-gray-200">
    <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500">
      <li>
        <button (click)="selectTab('ReimbursementNewTransation')" type="button"
          class="inline-flex items-center justify-center outline-0 p-4 border-b-2 group {{(varReimbursementNewTransation$|async)?activeTabClass:nonActiveTabClass}}">
          <i class="fal fa-file-import mr-2"></i> Request Reimbursement
        </button>
      </li>
      <li>
        <button (click)="selectTab('ReimbursementStatus')" type="button"
          class="inline-flex items-center justify-center outline-0 p-4 border-b-2 group {{(varReimbursementStatus$|async)?activeTabClass:nonActiveTabClass}}">
          <i class="fal fa-dot-circle mr-2"></i> Reimbursement Status
        </button>
      </li>
      <li>
        <button (click)="selectTab('ReimbursemrntByMe')" type="button"
          class="inline-flex items-center justify-center outline-0 p-4 border-b-2 group {{(varReimbursemrntByMe$|async)?activeTabClass:nonActiveTabClass}}">
          <i class="fa-light fa-image-portrait mr-2"></i> My Reimbursement Requests
        </button>
      </li>
      <li>
        <button (click)="selectTab('ReimbursemrntAllRequests')" type="button"
          class="inline-flex items-center justify-center outline-0 p-4 border-b-2 group {{(varReimbursemrntAllRequests$|async)?activeTabClass:nonActiveTabClass}}">
          <i class="fa-solid fa-list-check mr-2"></i> All Reimbursement Request
        </button>
      </li>
    </ul>
  </div>

  <div class="border-b p-4">

    <app-tab-reimbursement-all-request *ngIf="varReimbursemrntAllRequests$|async">
    </app-tab-reimbursement-all-request>

    <app-tab-reimbursement-by-me *ngIf="varReimbursemrntByMe$|async">
    </app-tab-reimbursement-by-me>

    <app-tab-reimbursement-new-transaction *ngIf="varReimbursementNewTransation$|async">
    </app-tab-reimbursement-new-transaction>

    <app-tab-reimbursement-status *ngIf="varReimbursementStatus$|async">
    </app-tab-reimbursement-status>

  </div>
</div>