import { Component, OnDestroy } from '@angular/core';
import { ManageApproverService } from './manage-approver-service/manage-approver.service';
import { BehaviorSubject, combineLatest, finalize, map, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';
import { MatDialog } from '@angular/material/dialog';
import { AddManageApproverComponent } from './manage-approver-forms/add-manage-approver/add-manage-approver.component';
import { EditManageApproverComponent } from './manage-approver-forms/edit-manage-approver/edit-manage-approver.component';
import { ApprovalLevelInterface } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { DeleteManageApproverComponent } from './manage-approver-forms/delete-manage-approver/delete-manage-approver.component';

@Component({
  selector: 'app-manage-approver',
  templateUrl: './manage-approver.component.html',
  styleUrls: ['./manage-approver.component.scss']
})
export class ManageApproverComponent implements OnDestroy {

  public readonly routes = Routes;

  private readonly _selectedApprovalLevel = new BehaviorSubject<ApprovalLevelInterface | undefined>(undefined);
  public readonly approvalLevels$ = new BehaviorSubject<ApprovalLevelInterface[] | undefined>([]);

  private readonly _reloaderApprovalLevels$ = new BehaviorSubject<void | undefined>(undefined);
  private readonly _subsApprovalLevel: Subscription = this._getSubsApprovers();

  constructor(
    public loader: LoaderService,

    private _dialog: MatDialog,
    private _manageApproverS: ManageApproverService,
  ) { }

  public ngOnDestroy(): void {

    this._subsApprovalLevel.unsubscribe();
  }

  public onClickDelete(level: ApprovalLevelInterface) {
    this._selectedApprovalLevel.next(level);
    this._dialog.open(DeleteManageApproverComponent, {
      data: {
        reloader$: this._reloaderApprovalLevels$,
        ApprovalLevel: this._selectedApprovalLevel.getValue(),
      } as ManageApproverMatDialogRefData,
    })
  }

  public onClickOpenCell(level: ApprovalLevelInterface) {
    this._selectedApprovalLevel.next(level);
    this._dialog.open(EditManageApproverComponent, {
      data: {
        reloader$: this._reloaderApprovalLevels$,
        ApprovalLevel: this._selectedApprovalLevel.getValue(),
      } as ManageApproverMatDialogRefData,
    })
  }

  public onClickNew() {
    this._dialog.open(AddManageApproverComponent, {
      data: {
        reloader$: this._reloaderApprovalLevels$,
      } as ManageApproverMatDialogRefData
    })
  }

  private _getSubsApprovers() {
    return combineLatest([
      this._reloaderApprovalLevels$.pipe(startWith()),
    ]).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._manageApproverS.getApprovalLevels().pipe(
        finalize(() => this.loader.finish()),
        map(values => values?.data),
        tap(values => this.approvalLevels$.next(values))
      ))
    ).subscribe()
  }
}

export interface ManageApproverMatDialogRefData {
  ApprovalLevel?: ApprovalLevelInterface | null;
  reloader$: BehaviorSubject<void | undefined>;
}
