import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home.component';
import { MenuComponent } from './header/menu/menu.component';
import { SublevelMenuComponent } from './header/menu/sublevel-menu/sublevel-menu.component';
import { BariconComponent } from './header/baricon/baricon.component';
import { AccMaterialsModule } from '../utilities/modules/acc-materials/acc-materials.module';
import { SettingiconComponent } from './header/settingicon/settingicon.component';
import { SettingdropdownComponent } from './header/settingdropdown/settingdropdown.component';
import { EditprofileiconComponent } from './header/settingdropdown/setting-icons/editprofileicon/editprofileicon.component';
import { LogouticonComponent } from './header/settingdropdown/setting-icons/logouticon/logouticon.component';
import { CopyrightComponent } from './header/copyright/copyright.component';
import { BrowserModule } from '@angular/platform-browser';
import { SwiperModule } from 'swiper/angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ClipboardModule } from 'ngx-clipboard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DisbursementModule } from './disbursement/disbursement.module';
import { AccCustomPipeModule } from '../utilities/modules/acc-custom-pipe/acc-custom-pipe.module';
import { DtrManagerComponent } from './dtr-manager/dtr-manager.component';
import { CustomComponentsModule } from '../custom-components/custom-components.module';
import { GeneralLedgerComponent } from './general-ledger/general-ledger.component';


@NgModule({
  declarations: [
    DashboardComponent,
    HomeComponent,
    MenuComponent,
    SublevelMenuComponent,
    BariconComponent,
    SettingiconComponent,
    SettingdropdownComponent,
    EditprofileiconComponent,
    LogouticonComponent,
    CopyrightComponent,
    DtrManagerComponent,
    GeneralLedgerComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    AccMaterialsModule,
    SwiperModule,
    NgApexchartsModule,
    ClipboardModule,
    BrowserModule,
    BrowserAnimationsModule,

    DisbursementModule,
    AccCustomPipeModule,
    CustomComponentsModule,
  ]
})
export class HomeModule { }
