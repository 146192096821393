import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Routes } from '../../utilities/classes/routes.class';
import { LoginService } from '../../authentication/login/login-service/login.service';
import { Helper } from '../../utilities/classes/helper.class';
import { AccMessageDialogService } from '../../custom-components/acc-message-dialog/acc-message-dialog-service/acc-message-dialog.service';
import { LoaderService } from '../../utilities/services/loader/loader.service';
import { AccountingApiService } from '../../utilities/services/accounting-api/accounting-api.service';
import { WaitHandler } from '../../utilities/classes/wait-handler.class';
import { finalize, map, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  public readonly Helper = Helper;
  public readonly routes = Routes;

  public readonly userData = this._loginS.userData;

  public readonly birthDayWaitHandler = new WaitHandler();
  public readonly birthDays$ = this._getBirthdayCelebrants();

  constructor(
    public loader: LoaderService,

    private _cdr: ChangeDetectorRef,
    private _accApi: AccountingApiService,
    private _dialog: AccMessageDialogService,
    private _loginS: LoginService,
  ) {
    if (!this._loginS.can(Helper.users.accessList['view-dashboard'])) {
      this._dialog.accessDenied(() => {
        this._loginS.logout();
      });
    }
  }

  private _getBirthdayCelebrants() {
    return of(null).pipe(
      tap(() => this.birthDayWaitHandler.newRequest(this._cdr)),
      switchMap(() => this._accApi.dashboard.getBirthdays().pipe(
        finalize(() => this.birthDayWaitHandler.finishRequest(this._cdr)),
        map(values => values.body),
        map(values => values.map((v: any) => ({ ...v, User: JSON.parse(v.User) }))),
      ))
    )
  }



}
