import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';

@Component({
  selector: 'app-reimbursement',
  templateUrl: './reimbursement.component.html',
  styleUrls: ['./reimbursement.component.scss']
})
export class ReimbursementComponent {

  public readonly routes = Routes;

  public readonly varReimbursementNewTransation$ = new BehaviorSubject(false);
  public readonly varReimbursementStatus$ = new BehaviorSubject(false);
  public readonly varReimbursemrntAllRequests$ = new BehaviorSubject(true);

  public readonly activeTabClass = 'text-red-600 border-red-600 bg-zinc-100 active';
  public readonly nonActiveTabClass = 'border-transparent hover:text-gray-600 hover:border-gray-300';

  public selectTab(tabName: "ReimbursementNewTransation" | "ReimbursementStatus" | "ReimbursemrntByMe" | "ReimbursemrntAllRequests") {
    this.varReimbursementNewTransation$.next(false);
    this.varReimbursementStatus$.next(false);
    this.varReimbursemrntAllRequests$.next(false);

    if (tabName == "ReimbursementNewTransation") this.varReimbursementNewTransation$.next(true);
    if (tabName == "ReimbursementStatus") this.varReimbursementStatus$.next(true);
    if (tabName == "ReimbursemrntAllRequests") this.varReimbursemrntAllRequests$.next(true);
  }

}
