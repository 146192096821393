import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CashReturnInterface, LiquidationInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';

@Component({
  selector: 'app-view-details-ca-request-liquidation',
  templateUrl: './view-details-ca-request-liquidation.component.html',
  styleUrls: ['./view-details-ca-request-liquidation.component.scss']
})
export class ViewDetailsCaRequestLiquidationComponent {

  constructor(
    public loader: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: CashReturnInterface| LiquidationInterface,

    private _matDialogRef: MatDialogRef<ViewDetailsCaRequestLiquidationComponent>,
  ) { }

  public onClose() {
    this._matDialogRef.close();
  }

  public isCR(obj: unknown): obj is CashReturnInterface {
    console.log("obj: ", (obj as CashReturnInterface).Amount);
    return (obj as CashReturnInterface).Amount !== undefined; 
  }

}
