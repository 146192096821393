import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { LiquidationService } from '../../../../liquidation-service/liquidation.service';
import { FormGroup, Validators } from '@angular/forms';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { PromptMessage } from 'src/app/accountancy/utilities/classes/promp-message.class';
import { finalize, of, Subscription, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-dialog-liquidation-to-cash-return',
  templateUrl: './dialog-liquidation-to-cash-return.component.html',
  styleUrls: ['./dialog-liquidation-to-cash-return.component.scss']
})
export class DialogLiquidationToCashReturnComponent {

  public readonly Helper = Helper;

  public readonly prompt = new PromptMessage();
  public readonly fgCashReturn = this._newFgForCashReturn();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LiquidationModifiedInterface,
    public loader: LoaderService,

    private _dialogRef: MatDialogRef<DialogLiquidationToCashReturnComponent>,
    private _liquidationS: LiquidationService,
  ) { }

  public onSubmit() {
    if (Helper.getInvalidControls(this.fgCashReturn, true).length) {
      this.prompt.set(Helper.errorMessage.requiredFields, 'alert alert-danger mb-4');
      return;
    }

    this.prompt.clear();

    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._liquidationS.toCashReturn(this.data.ID ?? -1, { Amount: Number(this.fgCashReturn.value.Amount) }).pipe(
        finalize(() => this.loader.finish())
      ))
    ).subscribe(Helper.api.handleStatusResponse({
      prompt: this.prompt,
      successMessage: '',
      onSuccess: () => {
        this.fgCashReturn.reset();
        this._dialogRef.close();
        this.data.reloader$.next();
        subs.unsubscribe();
      }
    }));
  }

  public onClose() {
    this._dialogRef.close();
  }

  private _newFgForCashReturn() {
    return new FormGroup({
      Amount: Helper.reactiveForms.control<number>(undefined, [Validators.required, Validators.max(Number(this.data.CA_Request.Amount))]),
    })
  }

}
