<div class="mb-4">
  <h5 class="text-[20px] text-heading font-bold mb-1 leading-none">Cash Advance</h5>
  <ul>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2">
      <a [routerLink]="routes.dashboard">Home</a>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2 translate-y-0">
      <i class="far fa-chevron-right"></i>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2">
      <a [routerLink]="routes.disbursementNavigator">Disbursement</a>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2 translate-y-0">
      <i class="far fa-chevron-right"></i>
    </li>
    <li class="text-[14px] font-semibold font-normal inline-block mr-2">
      Cash Advance
    </li>
  </ul>
</div>

<div class="bg-white p-4 mb-10">
  <div class="border-b border-gray-200">
    <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500">
      <li>
        <button (click)="selectTab('CAH')" type="button"
          class="inline-flex items-center justify-center outline-0 p-4 border-b-2 group {{(varCAH$|async)?activeTabClass:nonActiveTabClass}}">
          <i class="fa-solid fa-list-check mr-2"></i> All requests
        </button>
      </li>
      <li>
        <button (click)="selectTab('CAS')" type="button"
          class="inline-flex items-center justify-center outline-0 p-4 border-b-2 group {{(varCAS$|async)?activeTabClass:nonActiveTabClass}}">
          <i class="fal fa-dot-circle mr-2"></i> For approvals
        </button>
      </li>
      <li>
        <button (click)="selectTab('TR')" type="button"
          class="inline-flex items-center justify-center outline-0 p-4 border-b-2 group {{(varTR$|async)?activeTabClass:nonActiveTabClass}}">
          <i class="fal fa-file-import mr-2"></i> Request CA
        </button>
      </li>
    </ul>
  </div>

  <div class="border-b p-4">

    <app-tab-ca-new-transaction *ngIf="(varTR$|async)"></app-tab-ca-new-transaction>
    <app-tab-ca-status *ngIf="(varCAS$|async)"></app-tab-ca-status>
    <app-tab-ca-all-request *ngIf="(varCAH$|async)"></app-tab-ca-all-request>

  </div>
</div>