<div class="mb-4">
  <h5 class="text-[20px] text-heading font-bold mb-1 leading-none">General Ledger</h5>
  <ul>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2">
      <a [routerLink]="routes.dashboard">Home</a>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2 translate-y-0">
      <i class="far fa-chevron-right"></i>
    </li>
    <li class="text-[14px] font-semibold font-normal inline-block mr-2">
      General Ledger
    </li>
  </ul>
</div>

<div class="white-container">
  <app-acc-table [pagination]="pagination" tableTitle="General Ledger" [showTitle]="true" [showBulkPrint]="true"
    [showFilter]="true" #table>
    <table class="custom-table custom-table-default">
      <thead>
        <tr>
          <th>ID</th>
          <th>Account</th>
          <th>Details</th>
          <th>Method</th>
          <th>Action by</th>
          <th>Amount</th>
          <th>Client</th>
          <th>Detachment</th>
          <th>Datetime</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="waitHandler.processing">
          <td colspan="999">
            <app-acc-loader></app-acc-loader>
          </td>
        </tr>
        <ng-container *ngIf="generalLedgerList$|async as generalLedgerList">
          <tr *ngIf="!generalLedgerList.length&&!waitHandler.processing">
            <td colspan="999">No Data found...</td>
          </tr>
          <tr *ngFor="let item of generalLedgerList">
            <td class="whitespace-nowrap">{{item.ID}}</td>
            <td class="whitespace-nowrap">
              <div *ngIf="!item.Account.ID" class="text-muted">No data</div>
              <div *ngIf="item.Account.ID">
                ({{item.Account.ID}}) {{item.Account.Account}}
              </div>
              <div *ngIf="item.Account.Category?.ID" class="text-muted">
                ({{item.Account.Category?.ID}}) {{item.Account.Category?.Name}}
              </div>
            </td>
            <td class="whitespace-nowrap">{{item.Details}}</td>
            <td class="whitespace-nowrap">{{item.Method}}</td>
            <td class="whitespace-nowrap">
              <div *ngIf="!item.ActionBy.ID" class="text-muted">No data</div>
              <div *ngIf="item.ActionBy.ID">({{item.ActionBy.ID}}) {{item.ActionBy.Name}}</div>
            </td>
            <td class="whitespace-nowrap">{{item.Amount|currency:'₱ '}}</td>
            <td class="whitespace-nowrap">
              <div *ngIf="!item.Client.ID" class="text-muted">No data</div>
              <div *ngIf="item.Client.ID">({{item.Client.ID}}) {{item.Client.Name}}</div>
            </td>
            <td class="whitespace-nowrap">
              <div *ngIf="!item.Detachment.ID" class="text-muted">No data</div>
              <div *ngIf="item.Detachment.ID">({{item.Detachment.ID}}) {{item.Detachment.Name}}</div>
            </td>
            <td class="whitespace-nowrap">{{item.Datetime|formatDateTime}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </app-acc-table>
</div>