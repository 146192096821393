import { Component } from '@angular/core';
import { LiquidationService } from '../../liquidation-service/liquidation.service';
import { BehaviorSubject, combineLatest, finalize, forkJoin, map, mergeMap, Observable, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { WaitHandler } from 'src/app/accountancy/utilities/classes/wait-handler.class';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { Pagination } from 'src/app/accountancy/utilities/classes/pagination.class';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { ManageApproverService } from '../../../manage-approver/manage-approver-service/manage-approver.service';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { MatDialog } from '@angular/material/dialog';
import { AccSearchTipComponent } from 'src/app/accountancy/custom-components/acc-search-tip/acc-search-tip.component';
import { AccMessageDialogComponent, AccMessageDialogInterface } from 'src/app/accountancy/custom-components/acc-message-dialog/acc-message-dialog.component';
import { ApiGetResponse } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { CashAdvanceService } from '../../../cash-advance/cash-advance-service/cash-advance.service';
import { DialogLiquidationToCashReturnComponent } from './liquidation-dialogs/dialog-liquidation-to-cash-return/dialog-liquidation-to-cash-return.component';
import { DialogLiquidationToReimbursementComponent } from './liquidation-dialogs/dialog-liquidation-to-reimbursement/dialog-liquidation-to-reimbursement.component';
import { ViewDetailsCaRequestLiquidationComponent } from '../../view-details-ca-request-liquidation/view-details-ca-request-liquidation.component';
import { AccTableComponent } from 'src/app/accountancy/custom-components/acc-table/acc-table.component';
import { DialogLiquidationReceiptComponent } from './liquidation-dialogs/dialog-liquidation-receipt/dialog-liquidation-receipt.component';

@Component({
  selector: 'app-tab-for-liquidation',
  templateUrl: './tab-for-liquidation.component.html',
  styleUrls: ['./tab-for-liquidation.component.scss']
})
export class TabForLiquidationComponent {
  public checkAll = false;

  public readonly pagination = new Pagination();
  public readonly liquidationList$ = new BehaviorSubject<LiquidationModifiedInterface[]>([]);

  private readonly _subsGetLiquidationList = this._getSubsGetLiquidationList();

  constructor(
    public loader: LoaderService,

    private _caS: CashAdvanceService,
    private _manageApprovalS: ManageApproverService,
    private _loginS: LoginService,
    private _liquidationS: LiquidationService,
    private _matDialog: MatDialog,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetLiquidationList.unsubscribe();
  }

  public onClosedStatus(liq: LiquidationModifiedInterface) {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._liquidationS.updateLiquidationStatus(liq.ID ?? -1, 'Closed').pipe(
        finalize(() => {
          this.pagination.reloader$.next();
          this.loader.finish();
          subs.unsubscribe();
        })
      ))
    ).subscribe();
  }

  public onDisapproveStatus(liq: LiquidationModifiedInterface) {
    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._liquidationS.updateLiquidationStatus(liq.ID ?? -1, 'Disapproved').pipe(
        finalize(() => {
          this.pagination.reloader$.next();
          this.loader.finish();
          subs.unsubscribe();
        })
      ))
    ).subscribe();
  }

  public onApproveStatus(liq: LiquidationModifiedInterface) {
    const approveToNextStatus = () => {
      const subs: Subscription = of(null).pipe(
        tap(() => this.loader.request()),
        switchMap(() => this._liquidationS.updateLiquidationStatus(liq.ID ?? -1, liq.NextStatus ?? 'No Status').pipe(
          finalize(() => {
            this.pagination.reloader$.next();
            this.loader.finish();
            subs.unsubscribe();
          })
        )),
      ).subscribe();
    }

    if (!liq.NextStatus && liq.Status == 'Done') this._matDialog.open(DialogLiquidationReceiptComponent, { data: liq });
    else if (!liq.Status) this._matDialog.open(DialogLiquidationReceiptComponent, { data: liq });
    else approveToNextStatus();
  }

  public dialogs = {
    openForReimbursement: (liq: LiquidationModifiedInterface) => this._matDialog.open(DialogLiquidationToReimbursementComponent, { data: liq }),
    openForCashReturn: (liq: LiquidationModifiedInterface) => this._matDialog.open(DialogLiquidationToCashReturnComponent, { data: liq }),
    openViewMore: (liq: LiquidationModifiedInterface) => this._matDialog.open(ViewDetailsCaRequestLiquidationComponent, { data: liq }),
    openSearch: () => this._matDialog.open(AccSearchTipComponent),
    openPrint: async (e: AccTableComponent) => await e.printTable(),
  }

  public toggleCheckAll(e: Event) {
    const checked = (e.target as HTMLInputElement).checked;
    this._changeCheckForCAList(checked);
  }

  public transformStatus(status: string) {
    if (status == "Done") return 'success';
    if (status == "Disapproved") return 'danger';
    return 'warning';
  }

  private _changeCheckForCAList(newValue: boolean) {
    this.checkAll = newValue;
    this.liquidationList$.getValue().forEach(v => v.checked = newValue);
  }

  private _getSubsGetLiquidationList() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query)),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => this.loader.request()),
      switchMap(([query, limit]) => this._liquidationS.getForLiquidation(this.pagination.start, limit, query, null, this._loginS.userData?.ID).pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body),
        mergeMap(values => {
          if (!values) return of({ data: [], total_rows: 0 } as ApiGetResponse<LiquidationModifiedInterface>);

          const modifiedCaList = values?.data.map(v => of(v).pipe(
            switchMap(values => this._getNextStatus(values.Status).pipe(
              map(nextStatus => ({ ...values, NextStatus: nextStatus } as LiquidationModifiedInterface))
            ))
          ));

          if (!modifiedCaList.length) return of(values);

          return forkJoin(modifiedCaList).pipe(
            map(newList => ({ data: newList, total_rows: values.total_rows } as ApiGetResponse<LiquidationModifiedInterface>))
          );
        }),

        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.liquidationList$.next(values?.data.map(v => ({
            ...v,
            checked: false,
            reloader$: this.pagination.reloader$
          } as LiquidationModifiedInterface)) ?? []);
        }),
      ))
    ).subscribe();
  }

  private _getNextStatus(currentStatus: string): Observable<string | undefined> {
    if (currentStatus == "Done" || currentStatus == "Disapproved") return of(undefined);
    return of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._manageApprovalS.getApprovalLevels().pipe(
        finalize(() => this.loader.finish()),
        map(values => values?.data),
        map(values => {
          if (!values) return 'No Status';

          const currentIndex = values?.findIndex(v => v.Status == currentStatus);
          if (currentIndex < 0) return values[0].Status;

          return (currentIndex + 1 == values.length) ? 'Done' : values[currentIndex + 1].Status;
        })
      ))
    )
  }

}
