<form [formGroup]="fgApproverLevel" (submit)="onSubmit()" class="card md:min-w-[500px]">
  <div class="card-header">
    <div class="flex justify-between gap-2">
      New Approval Level
      <button (click)="onClose()" type="button" class="custom-button-close">
        <i class="fal fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body overflow-auto max-h-[400px]">
    <div class="grid grid-cols-12">
      <div class="col-span-12">
        <div class="mb-4">
          <span class="form-label">Status Name <span class="text-red-400">*</span></span>
          <input formControlName="Status" type="text" class="form-control">
          <div *ngIf="fgApproverLevel.get('Status')?.invalid && fgApproverLevel.get('Status')?.touched"
            class="text-red-400">
            <div *ngIf="fgApproverLevel.get('Status')?.errors?.['required']" class="text-sm">
              * Status Name is required
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12">
        <div formArrayName="Approvers" class="card">
          <div class="card-header">Approvers</div>
          <div class="card-body">
            <div *ngFor="let fc of getFaApprover().controls; let fci=index" class="mb-4">
              <span class="form-label">Approver Name <span class="text-red-400">*</span></span>
              <div class="form-group">
                <input [formControlName]="fci" placeholder="Select User" matInput [matAutocomplete]="auto_Approver"
                  type="text" class="form-group-input">
                <button (click)="removeApproverAt(fci)" type="button" class="custom-button form-group-label">
                  <i class="fa-light fa-trash-can"></i>
                </button>
                <mat-autocomplete #auto_Approver>
                  <mat-option *ngIf="loader.processing" disabled="">Searching...</mat-option>
                  <ng-container *ngIf="allUserListContainer$[fci] as userList$">
                    <ng-container *ngIf="userList$|async as userList">
                      <mat-option *ngIf="!userList.length" disabled="">Nothing found...</mat-option>
                      <mat-option *ngFor="let user of userList" [value]="user">{{user}}</mat-option>
                    </ng-container>
                  </ng-container>
                </mat-autocomplete>
              </div>
              <div *ngIf="fc.invalid && fc.touched" class="text-red-400">
                <div *ngIf="fc.errors?.['required']" class="text-sm">
                  * Approver Name is required
                </div>
                <div *ngIf="fc.errors?.['existIn']" class="text-sm">
                  * Approver Name does not exist
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="form-submit">
              <button (click)="addApprover()" type="button" class="custom-button custom-button-primary">
                <i class="fal fa-plus-circle"></i> Add Approver
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div *ngIf="loader.processing" class="alert alert-info mb-4">
      We are handling your request. Please wait.
    </div>
    <div *ngIf="prompt.message" class="{{prompt.type}}">{{prompt.message}}</div>
    <div class="form-submit">
      <button [disabled]="loader.processing" class="custom-button custom-button-primary-filled">
        <i class="fal fa-plus-circle"></i> Save
      </button>
    </div>
  </div>
</form>