<form [formGroup]="fgTR" (submit)="onSubmit()" class="card md:min-w-[500px]">
  <div class="card-header">
    For Liquidation
  </div>
  <div class="card-body">
    <div class="overflow-auto max-h-[250px] p-4 border shadow-sm">
      <div class="grid grid-cols-12 gap-4">

        <div class="col-span-12 sm:col-span-6">
          <span class="form-label"> Total cost <span class="text-red-400">*</span></span>
          <div class="form-group">
            <div class="form-group-label">₱</div>
            <input formControlName="Total_Cost" type="text" (keypress)="Helper.input.positiveNumeric($event)"
              class="form-group-input">
          </div>
          <div *ngIf="fgTR.get('Total_Cost')?.invalid && fgTR.get('Total_Cost')?.touched" class="text-red-500">
            <div *ngIf="fgTR.get('Total_Cost')?.errors?.['required']" class="text-sm">
              * Total cost is required.
            </div>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6">
          <div class="mb-4">
            <span class="form-label">
              Proof
              <span class="text-red-400">* </span>
              <span class="text-muted">(PDF Format)</span>
            </span>
            <div class="form-group">
              <div class="form-group-label">
                <i class="fa-light fa-receipt"></i>
              </div>
              <label class="form-group-input {{fgTR.get('Proof')?.value?'':'text-zinc-400'}}"
                for="tab-tr-updload-proof">
                {{fgTR.get('Proof')?.value?.name ||'Upload Proof'}}
              </label>
              <input (input)="onUploadProof($event)" type="file" class="hidden" placeholder="Upload Proof" accept=".pdf"
                id="tab-tr-updload-proof">
            </div>
            <div *ngIf="fgTR.get('Proof')?.invalid && fgTR.get('Proof')?.touched" class="text-red-500">
              * Proof is required.
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="alert alert-danger mb-4">
      <div class="font-semibold mb-2"><i class="fa-light fa-brake-warning"></i> Warning</div>
      Once submitted, values cannot be modified or altered. Please verify all fields before submitting.
    </div>
    <div *ngIf="waitHandler.processing" class="alert alert-info mb-4">
      We are handling your request. Please wait.
    </div>
    <div *ngIf="prompt.message" class="{{prompt.type}}">{{prompt.message}}</div>

    <div class="form-submit mb-4">
      <button [disabled]="waitHandler.processing" type="submit" class="custom-button custom-button-primary-filled">
        <i class="fal fa-plus-circle"></i> Save Transaction
      </button>
      <button (click)="onClose()" type="button" class="custom-button">Close</button>
    </div>
  </div>
</form>