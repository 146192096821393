import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Helper } from '../../utilities/classes/helper.class';
import { BehaviorSubject, combineLatest, finalize, map, startWith, Subscription, tap } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-acc-list',
  templateUrl: './acc-list.component.html',
  styleUrls: ['./acc-list.component.scss']
})
export class AccListComponent implements AfterViewInit {

  @Input('myClass') myClass: string | string[] = '';
  @Input('listTitle') listTitle: string = '';
  @Input('list') public list: string[] = [];
  @Input('withCheckbox') public withCheckbox: boolean = false;

  @Output('listValue') public listValue = new EventEmitter<{ checked: boolean, value: string }[]>();

  public readonly fgValueList$ = new BehaviorSubject<FormGroup<FgListInterface>[]>([]);

  public readonly fgListValue = this._newFgListValue();
  public readonly fgFilter = this._newFgFilter();

  public get customClass(): string {
    const myCustomClass = this.myClass;
    if (typeof myCustomClass == 'string') return myCustomClass;
    return myCustomClass.join(' ');
  }
  public checkAllToggle = false;

  constructor(
    private _cdr: ChangeDetectorRef,
  ) { }

  public ngAfterViewInit(): void {
    const fgs = this.list
      .map(v => new FormGroup<FgListInterface>({
        checked: Helper.reactiveForms.control<boolean>(false),
        value: Helper.reactiveForms.control<string>(v),
      }));

    fgs.forEach(fg => this.fgListValue.controls.list.push(fg, { emitEvent: false }));

    this.fgValueList$.next([...fgs.slice(0, this.fgFilter.value.Limit || undefined)]);
    this._cdr.detectChanges();
  }

  public onSelectAllChange() {
    this.fgValueList$.getValue().forEach(c => c.controls.checked.patchValue(this.checkAllToggle));
  }

  private _newFgListValue() {
    const fg = new FormGroup({
      list: new FormArray<FormGroup<FgListInterface>>([]),
    });

    const subs: Subscription = fg.valueChanges.pipe(
      finalize(() => subs.unsubscribe())
    ).subscribe(() => {

      this.listValue.emit(this.fgListValue.controls.list.controls.map(v => ({
        checked: v.value.checked ?? false,
        value: v.value.value ?? '',
      })));
      
    });

    return fg;
  }

  private _newFgFilter() {
    const Limit = Helper.reactiveForms.control<number>(5);
    const Search = Helper.reactiveForms.control<string>(undefined);

    const subs: Subscription = combineLatest([
      Limit.valueChanges.pipe(startWith(Limit.value)),
      Search.valueChanges.pipe(startWith(Search.value)),
    ]).pipe(
      finalize(() => subs.unsubscribe()),
      tap(([limit, query]) => {

        const fgs = this.fgListValue.controls.list.controls.
          filter(v => Helper.string.trimLowerCase(v.controls.value.value).includes(Helper.string.trimLowerCase(query)))
          .slice(0, limit || undefined);

        this.fgValueList$.next([...fgs]);

        this.checkAllToggle = false;
      }),
    ).subscribe();

    return new FormGroup({
      Limit,
      Search,
    });
  }

}

interface FgListInterface {
  checked: FormControl<boolean | undefined | null>,
  value: FormControl<string | undefined | null>,
}
