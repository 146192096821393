<app-acc-table [pagination]="pagination" [showBulkPrint]="true" (bulkPrint)="dialogs.openPrint($event)"
  tableTitle="Cash Return Approvals" [showTitle]="true">
  <table class="custom-table">
    <thead class="text-xs uppercase">
      <tr *ngIf="loader.processing"></tr>
      <tr *ngIf="!loader.processing" class="relaive">
        <th scope="col" class="bg-gray-50 sticky left-0">
          <input type="checkbox" (change)="toggleCheckAll($event)" [(ngModel)]="checkAll"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2">
        </th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Control Number</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Status</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Transaction Date</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Peso Amount </th>
        <th class="bg-gray-50 text-sm whitespace-nowrap text-center">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loader.processing">
        <td colspan="999">
          <app-acc-loader></app-acc-loader>
        </td>
      </tr>
      <ng-container *ngIf="!loader.processing">
        <ng-container *ngIf="crList$|async as crList">
          <tr *ngIf="!crList.length">
            <td colspan="999" class="bg-white sticky left-0">
              <div class="flex gap-2">
                No Data found...
                <div *ngIf="pagination.query" class="text-slate-500">
                  Can't find what your looking for?
                  <a (click)="dialogs.openSearch()" href="javascript:void(0);" class="text-blue-400 underline">
                    Search tips
                  </a>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of crList">
            <td class="bg-white sticky left-0 z-[1]">
              <input type="checkbox" [(ngModel)]="item.checked"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2">
            </td>
            <td> {{item.CA_Request.CntrlNumber||''}} </td>
            <td>
              <div class="pill pill-{{transformStatus|method:item.Status}} text-center">
                {{item.Status||'No Status'}}
              </div>
            </td>
            <td> {{item.CA_Request.TransactionDate|date}} </td>
            <td> {{item.Amount|currency:'₱'}} </td>
            <td class="flex-centered">
              <button [matMenuTriggerFor]="beforeMenu" class="custom-button-sm custom-button-primary">
                Action <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #beforeMenu xPosition="before">
                <button (click)="dialogs.openViewMore(item)" mat-menu-item>
                  <div class="text-sm"> <i class="fal fa-eye"></i> View More</div>
                </button>
                <hr>
                <button *ngIf="item.NextStatus" (click)="onApprove(item)" mat-menu-item>
                  <div class="text-sm"> <i class="fal fa-pencil"></i> Approve</div>
                </button>
                <button *ngIf="item.NextStatus" (click)="onDisapprove(item)" mat-menu-item>
                  <div class="text-sm"> <i class="fal fa-xmark"></i> Disapprove</div>
                </button>
              </mat-menu>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</app-acc-table>