export class PromptMessage {
  private promptMessage = '';
  private promptType = '';
  private timeout: any = undefined;

  public get message() {
    return this.promptMessage;
  }

  public get type() {
    return this.promptType;
  }

  /**
   * Set a prompt message
   * @param message the message to show
   * @param type type of the message
   * @param delay miliseconds of delay `default: 6000`
   */
  public set(message: string, type?: string, delay: number = 6000) {
    this.promptMessage = message;
    this.promptType = type ?? '';

    if (delay > 0) {
      this.clearCurrentTimeout();
      this.timeout = setTimeout(() => {
        this.clear();
      }, delay);
    }
  }

  public clear() {
    this.clearCurrentTimeout();
    this.promptMessage = '';
    this.promptType = '';
  }

  private clearCurrentTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  constructor() { }
}