import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-acc-list-service',
  templateUrl: './acc-list-service.component.html',
  styleUrls: ['./acc-list-service.component.scss']
})
export class AccListServiceComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title?: string | null,
      list: string[],
      myClass?: string | string[] | null,
      withCheckbox?: boolean | null,
      onChecksChange?: (values: { checked: boolean, value: string }[]) => void | null,
    },

    private _ref: MatDialogRef<AccListServiceComponent>,
  ) { }

  public onClose(){
    this._ref.close();
  }
}
