<app-acc-table [pagination]="pagination" [showBulkPrint]="true" (bulkPrint)="dialogs.openPrint($event)"
  tableTitle="Liquidation List" [showTitle]="true">
  <table class="custom-table">
    <thead class="text-xs uppercase">
      <tr *ngIf="loader.processing"></tr>
      <tr *ngIf="!loader.processing" class="relaive">
        <th class="bg-gray-50 text-sm whitespace-nowrap">Control Number</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Status</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Transaction Date</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Peso Amount </th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Dollar Amount </th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Expense Amount </th>
        <th class="bg-gray-50 text-sm whitespace-nowrap">Next Process</th>
        <th class="bg-gray-50 text-sm whitespace-nowrap text-center">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loader.processing">
        <td colspan="999">
          <app-acc-loader></app-acc-loader>
        </td>
      </tr>
      <ng-container *ngIf="!loader.processing">
        <ng-container *ngIf="liquidationList$|async as liquidationList">
          <tr *ngIf="!liquidationList.length">
            <td colspan="999" class="bg-white sticky left-0">
              <div class="flex gap-2">
                No Data found...
                <div *ngIf="pagination.query" class="text-slate-500">
                  Can't find what your looking for?
                  <a (click)="dialogs.openSearch()" href="javascript:void(0);" class="text-blue-400 underline">
                    Search tip
                  </a>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of liquidationList">
            <td> {{item.CA_Request.CntrlNumber||''}} </td>
            <td>
              <div class="pill pill-{{transformStatus|method:item.Status}} text-center">
                {{item.Status||'No Status'}}
              </div>
            </td>
            <td>{{item.CA_Request.TransactionDate|date}}</td>
            <td>{{item.CA_Request.Amount|currency:'₱'}}</td>
            <td>{{item.CA_Request.DollarAmount|currency:'$'}}</td>
            <td>{{item.Total_Cost|currency:'₱'}}</td>
            <td>
              <div class="whitespace-nowrap">{{item.NextProcess}}</div>
            </td>
            <td class="flex-centered">
              <button [matMenuTriggerFor]="beforeMenu" class="custom-button-sm custom-button-primary">
                Action <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #beforeMenu xPosition="before">
                <button (click)="dialogs.openViewMore(item)" mat-menu-item>
                  <div class="text-sm"> <i class="fal fa-eye"></i> View More</div>
                </button>
                <hr>
                <button *ngIf="item.Status=='Done'&&!item.NextStatus" (click)="onClosedStatus(item)" mat-menu-item>
                  <div class="text-sm"> Close</div>
                </button>
                <button *ngIf="!(item.Status=='For Cash Refund'||item.Status=='For Reimbursement')&&item.NextStatus"
                  (click)="onApproveStatus(item)" mat-menu-item>
                  <div class="text-sm"> <i class="fal fa-pencil"></i> {{item.NextStatus}}</div>
                </button>
                <button *ngIf="!(item.Status=='For Cash Refund'||item.Status=='For Reimbursement')&&item.NextStatus"
                  (click)="onDisapproveStatus(item)" mat-menu-item>
                  <div class="text-sm"> <i class="fal fa-xmark"></i> Disapprove</div>
                </button>
              </mat-menu>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</app-acc-table>