import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, finalize, map, of, Subscription, switchMap, tap } from 'rxjs';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { PromptMessage } from 'src/app/accountancy/utilities/classes/promp-message.class';
import { GlobalApiService } from 'src/app/accountancy/utilities/services/global-api/global-api.service';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { ReimbursementService } from '../../reimbursement-service/reimbursement.service';

@Component({
  selector: 'app-tab-reimbursement-new-transaction',
  templateUrl: './tab-reimbursement-new-transaction.component.html',
  styleUrls: ['./tab-reimbursement-new-transaction.component.scss']
})
export class TabReimbursementNewTransactionComponent implements OnDestroy {

  public readonly Helper = Helper;

  public readonly prompt = new PromptMessage();

  private readonly _fcPurpose3 = Helper.reactiveForms.control<string>('');
  private readonly _fcDueDate = Helper.reactiveForms.control<string>('', [Validators.required]);

  private readonly _fcPurpose2 = Helper.reactiveForms.control<string>('', [Validators.required]);
  private readonly _subsFcPurpose2onChange: Subscription = this._getSubsOnChangePupose2();

  private readonly _fcTransactionDate = Helper.reactiveForms.control<string>('', [Validators.required]);
  private readonly _subsFcTrantionDateOnChange = this._getSubsOnChangeTransactionDate();

  public readonly isOtherFcPurpose2$ = new BehaviorSubject(false);
  private readonly _subsIsOtherFcPurpose2: Subscription = this._getSubsOnChangePurpose2isOthers();

  public readonly fcChargeTo2isDetachment$ = new BehaviorSubject(false);

  public readonly fgTR = this._newFgNewTransaction();
  public get getPuposeIsInvalid() { return this._getPuposeIsInvalid(); };
  public get getCoveredDateInvalid() { return this._getCoveredDateInvalid(); };

  constructor(
    public loader: LoaderService,

    private _reimbursementS: ReimbursementService,
    private _globalApiS: GlobalApiService,
    private _loginS: LoginService,
  ) { }

  public ngOnDestroy(): void {
    this._subsFcTrantionDateOnChange.unsubscribe();
    this._subsFcPurpose2onChange.unsubscribe();
    this._subsIsOtherFcPurpose2.unsubscribe();
  }

  public onSubmit() {
    if (Helper.getInvalidControls(this.fgTR, true).length) {
      this.prompt.set(Helper.errorMessage.requiredFields, 'alert alert-danger mb-4');
      return;
    }

    this.prompt.clear();

    const formData = this.fgTR.value;
    const [year, month, date, day, h, m, s, ms] = Helper.date.dateDestruct(new Date());
    const fileName = `CA_${year}${month}${date}${day}${h}${m}${s}${ms}_${formData.Proof?.name.trim()}`;

    const formBody = {
      Purpose: `${formData.Purpose1}${formData.Purpose2 ? ': ' + formData.Purpose2 : ''}${formData.Purpose3 ? ': ' + formData.Purpose3 : ''}`,
      Product: `${formData.Product1}${formData.Product2 ? ': ' + formData.Product2 : ''}`,
      CoveredDateFrom: Helper.date.format(formData.CoveredDateFrom, 1, '-'),
      CoveredDateTo: Helper.date.format(formData.CoveredDateTo, 1, '-'),
      TransactionDate: Helper.date.format(formData.TransactionDate, 1, '-'),
      DueDate: Helper.date.format(formData.DueDate, 1, '-'),
      ReimbursementAmount: Number(formData.ReimbursementAmount),
      Proof: fileName,
      Description: formData.Description,
      Remarks: formData.Remarks,
      RequestedBy: this._loginS.userData?.ID,
    };

    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._globalApiS.getUploadFileUrl(formData.Proof as File, fileName, 'Receipts').pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body?.presignedURL),
        switchMap(values => of(values).pipe(
          tap(() => this.loader.request()),
          switchMap(url => this._globalApiS.putFileInServer(url ?? '', formData.Proof as File).pipe(
            finalize(() => this.loader.finish()),
            switchMap(() => of(null).pipe(
              tap(() => this.loader.request()),
              switchMap(() => this._globalApiS.convertPhpToUsd(formBody.ReimbursementAmount).pipe(
                finalize(() => this.loader.finish()),
                map(values => ({ ...formBody, DollarAmount: values.rates?.USD })),
                switchMap(values => of(values).pipe(
                  tap(() => this.loader.request()),
                  switchMap(values => this._reimbursementS.newReimbursement(values).pipe(
                    finalize(() => this.loader.finish()),
                  ))
                ))
              ))
            ))
          ))
        )),
      ))
    ).subscribe(Helper.api.handleStatusResponse({
      prompt: this.prompt,
      successMessage: 'Successfully saved',
      onSuccess: () => {
        this.fgTR.reset();
        subs.unsubscribe();
      }
    }));
  }
  public onUploadProof(e: Event) {
    const files = (e.target as HTMLInputElement).files as FileList;
    this.fgTR.get('Proof')?.patchValue(files[0]);
  }

  private _getCoveredDateInvalid() {
    return (
      (this.fgTR.get('CoveredDateFrom')?.invalid && this.fgTR.get('CoveredDateFrom')?.touched) ||
      (this.fgTR.get('CoveredDateTo')?.invalid && this.fgTR.get('CoveredDateTo')?.touched)
    )
  }

  private _getPuposeIsInvalid() {
    return (
      (this.fgTR.get('Purpose1')?.invalid && this.fgTR.get('Purpose1')?.touched) ||
      (this._fcPurpose2?.invalid && this._fcPurpose2?.touched) ||
      (this._fcPurpose3?.invalid && this._fcPurpose3?.touched)
    )
  }

  private _newFgNewTransaction() {
    return new FormGroup({
      Purpose1: Helper.reactiveForms.control<string>('', [Validators.required]),
      Purpose2: this._fcPurpose2,
      Purpose3: this._fcPurpose3,
      Product1: Helper.reactiveForms.control<string>('', [Validators.required]),
      Product2: Helper.reactiveForms.control<string>('', [Validators.required]),
      CoveredDateFrom: Helper.reactiveForms.control<string>('', [Validators.required]),
      CoveredDateTo: Helper.reactiveForms.control<string>('', [Validators.required]),
      TransactionDate: this._fcTransactionDate,
      DueDate: this._fcDueDate,
      ReimbursementAmount: Helper.reactiveForms.control<string>('', [Validators.required]),
      Proof: Helper.reactiveForms.control<File>(undefined, [Validators.required]),
      Description: Helper.reactiveForms.control<string>('', [Validators.required]),
      Remarks: Helper.reactiveForms.control<string>(''),
    });
  }

  private _getSubsOnChangeTransactionDate() {
    return this._fcTransactionDate.valueChanges.subscribe(() => this._fcDueDate.reset());
  }

  private _getSubsOnChangePupose2() {
    return this._fcPurpose2.valueChanges.subscribe(newVal => {
      this.isOtherFcPurpose2$.next(newVal == "Others");
    });
  }

  private _getSubsOnChangePurpose2isOthers() {
    return this.isOtherFcPurpose2$.subscribe(newVal => {
      if (newVal) this._fcPurpose3.setValidators([Validators.required]);
      else this._fcPurpose3.clearValidators();

      this._fcPurpose3.setValue('');
      this._fcPurpose3.updateValueAndValidity();
    })
  }





}
