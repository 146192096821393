<form [formGroup]="fgCashReturn" (submit)="onSubmit()" class="card md:min-w-[500px]">
  <div class="card-header">For Cash Return</div>
  <div class="card-body">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <span class="form-label"> Cash Return Amount <span class="text-red-400">*</span></span>
        <div class="form-group">
          <div class="form-group-label">₱</div>
          <input formControlName="Amount" type="text" (keypress)="Helper.input.positiveNumeric($event)"
            class="form-group-input">
        </div>
        <div *ngIf="fgCashReturn.get('Amount')?.invalid && fgCashReturn.get('Amount')?.touched" class="text-red-500">
          <div *ngIf="fgCashReturn.get('Amount')?.errors?.['required']" class="text-sm">
            * Amount is required.
          </div>
          <div *ngIf="fgCashReturn.get('Amount')?.errors?.['max']" class="text-sm">
            * Invalid amount. Max is {{data.CA_Request.Amount}}.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="alert alert-danger mb-4">
      <div class="font-semibold mb-2"><i class="fa-light fa-brake-warning"></i> Warning</div>
      Once submitted, values cannot be modified or altered. Please verify all fields before submitting.
    </div>
    <div *ngIf="loader.processing" class="alert alert-info mb-4">
      We are handling your request. Please wait.
    </div>
    <div *ngIf="prompt.message" class="{{prompt.type}}">{{prompt.message}}</div>

    <div class="form-submit mb-4">
      <button [disabled]="loader.processing" type="submit" class="custom-button custom-button-primary-filled">
        <i class="fal fa-plus-circle"></i> Save Transaction
      </button>
      <button (click)="onClose()" type="button" class="custom-button">Close</button>
    </div>
  </div>
</form>