<div class="mb-4">
  <h5 class="text-[20px] text-heading font-bold mb-1 leading-none">Manage Approvers</h5>
  <ul>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2">
      <a [routerLink]="routes.dashboard">Home</a>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2 translate-y-0">
      <i class="far fa-chevron-right"></i>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2">
      <a [routerLink]="routes.disbursement">Disbursement</a>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2 translate-y-0">
      <i class="far fa-chevron-right"></i>
    </li>
    <li class="text-[14px] font-semibold font-normal inline-block mr-2">
      Manage Approvers
    </li>
  </ul>
</div>

<div class="container">

  <div class="grid grid-cols-12 gap-4">
    <div class="col-span-12 md:col-span-6">
      <div class="card">
        <div class="card-header">Approval Levels</div>
        <div class="card-body mx-4">
          <div class="flex-centered">
            <div *ngIf="loader.processing" class="loader-contianer">
              <div class="loader"></div>
            </div>
          </div>
          <div *ngIf="!loader.processing" class="stepper-container">

            <div *ngFor="let level of approvalLevels$|async; let i=index" class="stepper-item">
              <div (click)="onClickOpenCell(level)" class="stepper-number">
                <div class="text-white">{{(i+1)|numberPlacement}}</div>
              </div>
              <div class="font-medium ml-2 text-black">{{level.Status}}</div>
              <div class="text-sm ml-2">
                {{(i+1)|numberPlacement}} level of approval
                <ng-container>
                  | <a (click)="onClickDelete(level)" class="text-red-400 hover:text-red-300"
                    href="javascript:void(0);">
                    Delete
                  </a>
                </ng-container>
              </div>
            </div>

            <div class="stepper-item">
              <div (click)="onClickNew()" class="stepper-number">
                <div class="text-white">+</div>
              </div>
              <div class="font-medium ml-2 text-black">New Level</div>
              <div class="text-sm ml-2">Add new lever of approver</div>
            </div>

          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>

  </div>

</div>