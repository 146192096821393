import { Component } from '@angular/core';

@Component({
  selector: 'app-receive-reimbursement',
  templateUrl: './receive-reimbursement.component.html',
  styleUrls: ['./receive-reimbursement.component.scss']
})
export class ReceiveReimbursementComponent {

}
