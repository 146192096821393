import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, finalize, map, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { PromptMessage } from 'src/app/accountancy/utilities/classes/promp-message.class';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { CashAdvanceService } from '../../cash-advance-service/cash-advance.service';
import { CustomValidator } from 'src/app/accountancy/utilities/validators/custom-validator';
import { GlobalApiService } from 'src/app/accountancy/utilities/services/global-api/global-api.service';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';

@Component({
  selector: 'app-tab-ca-new-transaction',
  templateUrl: './tab-ca-new-transaction.component.html',
  styleUrls: ['./tab-ca-new-transaction.component.scss']
})
export class TabCaNewTransactionComponent {


  public readonly prompt = new PromptMessage();
  public readonly Helper = Helper;

  private readonly _fcChargeTo1 = new FormControl('', {
    validators: [Validators.required],
    asyncValidators: [
      CustomValidator.async.existIn(of(null).pipe(
        tap(() => this.loader.request()),
        switchMap(() => this._globalApiS.getDepartmentList().pipe(
          finalize(() => this.loader.finish()),
          map(values => values.map(v => v.Name)),
        )))
      )
    ],
  });

  private readonly _fcChargeTo3 = new FormControl('', {
    asyncValidators: [
      CustomValidator.async.existIn(of(null).pipe(
        tap(() => this.loader.request()),
        switchMap(() => this._globalApiS.getDetachmentList().pipe(
          finalize(() => this.loader.finish()),
          map(values => values?.map(v => v.Detachment) ?? []),
        )))
      )
    ],
  });

  public readonly fcPurpose2isOthers$ = new BehaviorSubject(false);
  public readonly fcChargeTo2isDetachment$ = new BehaviorSubject(false);

  public readonly fgTR = new FormGroup({
    Purpose1: new FormControl('', [Validators.required]),
    Purpose2: new FormControl('', [Validators.required]),
    Purpose3: new FormControl(''),
    DateOfUse: new FormControl('', [Validators.required]),
    TransactionDate: new FormControl('', [Validators.required]),
    DueDate: new FormControl('', [Validators.required]),
    Amount: new FormControl('', [Validators.required, CustomValidator.numeric]),
    Description: new FormControl('', [Validators.required]),
    ChargeTo3: this._fcChargeTo3,
    ChargeTo2: new FormControl('', [Validators.required]),
    ChargeTo1: this._fcChargeTo1,
    Remarks: new FormControl(''),
  });

  public get getPuposeIsInvalid() {
    return (
      (this.fgTR.get('Purpose1')?.invalid && this.fgTR.get('Purpose1')?.touched) ||
      (this.fgTR.get('Purpose2')?.invalid && this.fgTR.get('Purpose2')?.touched) ||
      (this.fgTR.get('Purpose3')?.invalid && this.fgTR.get('Purpose3')?.touched)
    );
  }

  public get getChargeToIsInvalid() {
    return (
      (this.fgTR.get('ChargeTo1')?.invalid && this.fgTR.get('ChargeTo1')?.touched) ||
      (this.fgTR.get('ChargeTo2')?.invalid && this.fgTR.get('ChargeTo2')?.touched) ||
      (this.fgTR.get('ChargeTo3')?.invalid && this.fgTR.get('ChargeTo3')?.touched)
    );
  }

  public readonly departmentList$ = this._fcChargeTo1.valueChanges.pipe(
    startWith(this._fcChargeTo1.value),
    tap(() => this.loader.request(this._cdr)),
    switchMap(query => this._globalApiS.getDepartmentList().pipe(
      finalize(() => this.loader.finish(this._cdr)),
      map(values => {
        return values?.filter(v => Helper.string.trimLowerCase(v.Name).includes(Helper.string.trimLowerCase(query ?? '')))
      }),
    ))
  );

  public readonly detachmentList$ = this._fcChargeTo3.valueChanges.pipe(
    startWith(this._fcChargeTo3.value),
    tap(() => this.loader.request(this._cdr)),
    switchMap(query => this._globalApiS.getDetachmentList().pipe(
      finalize(() => this.loader.finish(this._cdr)),
      map(values => {
        return values?.filter(v => Helper.string.trimLowerCase(v.Detachment).includes(Helper.string.trimLowerCase(query ?? '')))
      }),
    ))
  );

  private readonly _subsOnChangePurpose2: Subscription = this._initOChangePurpose2();
  private readonly _subsOnChangeChargeTo2: Subscription = this._initOnChangeChargeTo2();

  constructor(
    public loader: LoaderService,

    private _loginS: LoginService,
    private _globalApiS: GlobalApiService,
    private _cashAdvanceS: CashAdvanceService,
    private _cdr: ChangeDetectorRef,
  ) { }

  public ngOnDestroy(): void {

    this._subsOnChangePurpose2.unsubscribe();
    this._subsOnChangeChargeTo2.unsubscribe();
  }

  public onSubmit() {

    if (Helper.getInvalidControls(this.fgTR).length) {
      this.prompt.set(Helper.errorMessage.requiredFields, 'alert alert-danger', 6000);
      return;
    }

    this.prompt.clear();

    const formData = this.fgTR.value;

    const formBody = {
      Purpose: `${formData.Purpose1}${formData.Purpose2 ? ': ' + formData.Purpose2 : ''}${formData.Purpose3 ? ': ' + formData.Purpose3 : ''}`,
      DateOfUse: Helper.date.format(formData.DateOfUse, 1, '-'),
      TransactionDate: Helper.date.format(formData.TransactionDate, 1, '-'),
      DueDate: Helper.date.format(formData.DueDate, 1, '-'),
      Amount: Number(formData.Amount),
      DollarAmount: 0,
      Description: formData.Description,
      ChargeTo: `${formData.ChargeTo1}${formData.ChargeTo2 ? ': ' + formData.ChargeTo2 : ''}${formData.ChargeTo3 ? ': ' + formData.ChargeTo3 : ''}`,
      Remarks: formData.Remarks,
      RequestedBy: this._loginS.userData?.ID,
    };

    const subs: Subscription = of(null).pipe(
      tap(() => this.loader.request()),
      switchMap(() => this._globalApiS.convertPhpToUsd(formBody.Amount).pipe(
        finalize(() => this.loader.finish()),
        tap(console.log),
        tap(() => this.loader.request()),
        switchMap(values => this._cashAdvanceS.newCashAdvanceRequest({ ...formBody, DollarAmount: values?.rates?.USD }).pipe(
          finalize(() => this.loader.finish()),
        ))
      )),
    ).subscribe(Helper.api.handleStatusResponse({
      prompt: this.prompt,
      successMessage: 'Successfully saved',
      onSuccess: () => {
        this.fgTR.reset();
        subs.unsubscribe();
      }
    }));
  }

  private _initOChangePurpose2() {
    this.fgTR.get('Purpose2')?.valueChanges.subscribe(v => this.fcPurpose2isOthers$.next(v == 'Others'));

    return this.fcPurpose2isOthers$.subscribe(v => {
      if (v) this.fgTR.get('Purpose3')?.setValidators([Validators.required]);
      else this.fgTR.get('Purpose3')?.clearValidators();

      this.fgTR.get('Purpose3')?.setValue('');
      this.fgTR.get('Purpose3')?.updateValueAndValidity();
    });
  }

  private _initOnChangeChargeTo2() {
    this.fgTR.get('ChargeTo2')?.valueChanges.subscribe(v => this.fcChargeTo2isDetachment$.next(v == 'With Detachment'));

    return this.fcChargeTo2isDetachment$.subscribe(v => {
      if (v) this.fgTR.get('ChargeTo3')?.setValidators([Validators.required]);
      else this.fgTR.get('ChargeTo3')?.clearValidators();

      this.fgTR.get('ChargeTo3')?.setValue('');
      this.fgTR.get('ChargeTo3')?.updateValueAndValidity();
    });
  }

}
