import { NgModule } from '@angular/core';
import { ArrayFirstCustomPipe, ArrMapCustomPipe, ArrToStrCustomPipe, ConsoleLogCustomPipe, EvaluateCustomPipe, FormatDateCustomPipe, FormatDateTimeCustomPipe, FormatTimeCustomPipe, FunctionCustomPipe, InvalidControlCustomPipe, JoinPathCustomPipe, MethodCustomPipe, NumberPlacementCustomPipe, ObjToArrCustomPipe, PadEndCustomPipe, PadStartCustomPipe, ReplaceCustomPipe, RoundoffCustomPipe, StringifyCustomPipe, StrLimitCustomPipe, ToStrCustomPipe, UppcaseOnCustomPipe } from '../../custom-pipes/custom-pipes.pipe';



@NgModule({
  declarations: [
    ReplaceCustomPipe,
    FormatDateCustomPipe,
    FormatTimeCustomPipe,
    FormatDateTimeCustomPipe,
    MethodCustomPipe,
    FunctionCustomPipe,
    StringifyCustomPipe,
    RoundoffCustomPipe,
    EvaluateCustomPipe,
    PadStartCustomPipe,
    PadEndCustomPipe,
    ArrToStrCustomPipe,
    ArrMapCustomPipe,
    StrLimitCustomPipe,
    ObjToArrCustomPipe,
    ConsoleLogCustomPipe,
    UppcaseOnCustomPipe,
    NumberPlacementCustomPipe,
    InvalidControlCustomPipe,
    ArrayFirstCustomPipe,
    JoinPathCustomPipe,
    ToStrCustomPipe,
  ],
  exports: [
    ReplaceCustomPipe,
    FormatDateCustomPipe,
    FormatTimeCustomPipe,
    FormatDateTimeCustomPipe,
    MethodCustomPipe,
    FunctionCustomPipe,
    StringifyCustomPipe,
    RoundoffCustomPipe,
    EvaluateCustomPipe,
    PadStartCustomPipe,
    PadEndCustomPipe,
    ArrToStrCustomPipe,
    ArrMapCustomPipe,
    StrLimitCustomPipe,
    ObjToArrCustomPipe,
    ConsoleLogCustomPipe,
    UppcaseOnCustomPipe,
    NumberPlacementCustomPipe,
    InvalidControlCustomPipe,
    ArrayFirstCustomPipe,
    JoinPathCustomPipe,
    ToStrCustomPipe,
  ],
  imports: []
})
export class AccCustomPipeModule { }
