<div class="card md:min-w-[500px]">
  <div class="card-header">For Cash Refund</div>
  <div class="card-body">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <div class="alert alert-warning mb-4">
          <div class="font-semibold mb-2"><i class="fa-light fa-brake-warning"></i> Confirmation required.</div>
          Please confirm the amount for cash refund.
        </div>
      </div>
      <div class="col-span-12">
        <div class="form-label text-center"> Cash Refund Amount</div>
        <div class="font-semibold text-center">{{getCashRefundAmount|currency:'₱ '}}</div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div *ngIf="waitHandler.processing" class="alert alert-info mb-4">
      We are handling your request. Please wait.
    </div>
    <div *ngIf="prompt.message" class="{{prompt.type}}">{{prompt.message}}</div>

    <div class="form-submit mb-4">
      <button [disabled]="waitHandler.processing" (click)="onSubmit()" type="submit"
        class="custom-button custom-button-primary-filled">
        <i class="fal fa-plus-circle"></i> Confirm Transaction
      </button>
      <button (click)="onClose()" type="button" class="custom-button">Close</button>
    </div>
  </div>
</div>