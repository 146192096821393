import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { WaitHandler } from '../../../../../../../utilities/classes/wait-handler.class';
import { PromptMessage } from 'src/app/accountancy/utilities/classes/promp-message.class';
import { LiquidationService } from '../../../../liquidation-service/liquidation.service';
import { finalize, map, of, Subscription, switchMap, tap } from 'rxjs';
import { GlobalApiService } from 'src/app/accountancy/utilities/services/global-api/global-api.service';
import { ReimbursementService } from 'src/app/accountancy/home/disbursement/reimbursement/reimbursement-service/reimbursement.service';

@Component({
  selector: 'app-dialog-liquidation-to-reimbursement',
  templateUrl: './dialog-liquidation-to-reimbursement.component.html',
  styleUrls: ['./dialog-liquidation-to-reimbursement.component.scss']
})
export class DialogLiquidationToReimbursementComponent {

  public readonly Helper = Helper;

  public readonly waitHandler = new WaitHandler();
  public readonly prompt = new PromptMessage();

  public get getCashRefundAmount() { return (this.data.Total_Cost ?? 0) - this.data.CA_Request.Amount; };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LiquidationModifiedInterface,

    private _dialogRef: MatDialogRef<DialogLiquidationToReimbursementComponent>,
    private _globalApiS: GlobalApiService,
    private _reimbursementS: ReimbursementService,
  ) { }

  public onSubmit() {
    const formBody = {
      Purpose: this.data.CA_Request.Purpose,
      Product: "Cash Advance",
      CoveredDateFrom: Helper.date.format(this.data.CA_Request.DateOfUse, 1, '-'),
      CoveredDateTo: Helper.date.format(this.data.CA_Request.DueDate, 1, '-'),
      TransactionDate: Helper.date.format(new Date(), 1, '-'),
      DueDate: Helper.date.format(Helper.date.addDay(new Date(), 30), 1, '-'),
      ReimbursementAmount: Number(this.data.CA_Request.Amount),
      Proof: this.data.Proof,
      Description: this.data.CA_Request.Description,
      Remarks: this.data.CA_Request.Remarks,
      RequestedBy: this.data.CA_Request.RequestedBy.ID,
    };

    const subs: Subscription = of(null).pipe(
      tap(() => this.waitHandler.newRequest()),
      switchMap(() => of(null).pipe(
        tap(() => this.waitHandler.newRequest()),
        switchMap(() => this._globalApiS.convertPhpToUsd(formBody.ReimbursementAmount).pipe(
          finalize(() => this.waitHandler.finishRequest()),
          map(values => ({ ...formBody, DollarAmount: values.rates?.USD })),
          switchMap(values => of(values).pipe(
            tap(() => this.waitHandler.newRequest()),
            switchMap(values => this._reimbursementS.newReimbursement(values).pipe(
              finalize(() => {
                this.waitHandler.finishRequest();
                this._dialogRef.close();
              }),
            ))
          ))
        ))
      ))
    ).subscribe(Helper.api.handleStatusResponse({
      prompt: this.prompt,
      successMessage: 'Successfully saved',
      onSuccess: () => subs.unsubscribe(),
    }));
  }

  public onClose() {
    this._dialogRef.close();
  }

  // public get getInitialAmount() {
  //   return Number(((this.data.Total_Cost ?? 0) - this.data.CA_Request.Amount).toFixed(2));
  // }

  // constructor(
  //   @Inject(MAT_DIALOG_DATA) public data: LiquidationModifiedInterface,
  //   public loader: LoaderService,

  //   private _dialogRef: MatDialogRef<DialogLiquidationToReimbursementComponent>,
  // ) { }

  // public onClose() {
  //   this._dialogRef.close();
  // }

}
