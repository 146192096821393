<div class="mb-5">
  <h5 class="text-[20px] text-heading font-bold mb-1 leading-none">Dashboard</h5>
  <ul>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2">
      <a [routerLink]="routes.dashboard">Home</a>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2 translate-y-0">
      <i class="far fa-chevron-right"></i>
    </li>
    <li class="text-[14px] font-semibold font-normal inline-block mr-2">
      Dashboard
    </li>
  </ul>
</div>

<div class="grid grid-cols-12 gap-4">
  <div class="col-span-12 sm:col-span-8">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 md:col-span-6">
        <div class="card">
          <div class="card-header">
            <i class="fa-solid fa-heart text-red-400"></i> Good day!
            <div class="float-right">
              <div class="text-muted text-sm text-end">{{Helper.date.newDate|date}}</div>
            </div>
          </div>
          <div class="card-body">
            Welcome back!
          </div>
          <div class="card-footer">

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-span-12 sm:col-span-4">
    <ng-container *ngIf="birthDayWaitHandler.processing">
      <app-acc-loader></app-acc-loader>
    </ng-container>
    <ng-container *ngIf="birthDays$|async as birthDays">
      <div *ngFor="let item of birthDays" class="white-container border shadow-md mb-4">
        <div class="flex items-center gap-4">
          <div class="border-2 border-red-400 rounded-full w-[40px] h-[40px] flex-centered">
            <i class="fa-solid fa-cake-candles text-red-400"></i>
          </div>
          <div>
            It's
            <a [href]="[Helper.env.websiteURL,'home','account','user-profile','AGF-'+item.ID|toStr|padStart:5:'0']|joinPath"
              class="hover:underline whitespace-nowrap text-red-400">
              {{item.User.LastName}} {{item.User.FirstName}}
            </a>
            birthday!
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>