import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from "@angular/forms";
import { catchError, debounceTime, distinctUntilChanged, map, Observable, of } from "rxjs";

export class CustomValidator {

  public static async = {
    /**
     * `Async Validator`
     * 
     * Check if control value is existing in string array
     */
    existIn: (optionList: Observable<string[] | null | undefined>): AsyncValidatorFn => {
      return (control: AbstractControl) => {
        if (!control.value) {
          return of(null);
        }

        return optionList.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          map(options => {
            return options?.includes(control.value) ? null : { existIn: true };
          }),
          catchError(() => of(null))
        )
      }
    }
  };

  public static numeric(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    const value: string = control.value;

    const valid = !isNaN(Number(value));

    return valid ? null : { numeric: true };
  }

  /**
   * `Validator`
   * 
   * Check if control value is existing in string array
   */
  public static existIn(list: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const valid = list.find(v => v == control.value);
      return valid!! ? null : { existIn: true };
    }
  }

  /**
   * `Validator`
   * 
   * Check if control value follows the phone number format `09xx` and `length is 11`.
   */
  public static phoneNumber(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    const value: string = control.value;

    const isFirstValid = value[0] == '0';
    const isSecondValid = value[1] == '9';
    const isCorrectLength = value.length == 11;

    const valid = isFirstValid && isSecondValid && isCorrectLength;

    return valid ? null : { phoneNumber: true };

  }

  /**
   * `Validator`
   * 
   * Calculate the age from the date and check if it is greater than 18.
   */
  public static isAdult(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const dateOfBirth = new Date(control.value);
    const today: Date = new Date();

    today.setHours(0, 0, 0, 0);
    dateOfBirth.setHours(0, 0, 0, 0);

    const ageInMilliseconds = Math.abs(today.getTime() - dateOfBirth.getTime());
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365);

    const valid = ageInYears > 18;

    return valid ? null : { isAdult: true };
  }

  private constructor() { }
}