import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Pagination } from '../../utilities/classes/pagination.class';
import { Helper } from '../../utilities/classes/helper.class';
import { AccMessageDialogService } from '../acc-message-dialog/acc-message-dialog-service/acc-message-dialog.service';

@Component({
  selector: 'app-acc-table',
  templateUrl: './acc-table.component.html',
  styleUrls: ['./acc-table.component.scss'],
})
export class AccTableComponent {
  @ViewChild('tableContainer') public tableContainer!: ElementRef;

  @Input('showTitle') public showTitle: boolean = false;
  @Input('showBulkDelete') public showBulkDelete: boolean = false;
  @Input('showBulkPrint') public showBulkPrint: boolean = false;

  @Input('pagination') public pagination = new Pagination();
  @Input('tableTitle') public tableTitle?: string | null;
  @Output('bulkDelete') public bulkDelete = new EventEmitter();
  @Output('bulkPrint') public bulkPrint = new EventEmitter<AccTableComponent>();

  constructor(

    private _msgDialog: AccMessageDialogService
  ) { }

  public readonly Helper = Helper;

  public onBulkPrint() {
    this.bulkPrint.emit(this);
  }

  public onBulkDelete() {
    this.bulkDelete.emit();
  }

  public async printTable() {
    try {
      const dialog = this._msgDialog.open({
        title: "Print",
        message: "Please wait while we generate your document.",
        disableClodeButton: true,
      }, true);

      const documentContent: string = await fetch('assets/templates/acc-table-print.html').then(data => data.text());
      const printWindow = window.open(this.tableTitle ?? 'No Title', this.tableTitle ?? 'No Title', 'width=500,height=400') as Window;
      printWindow.document.write(`<script> 
        const title = \`${this.tableTitle ?? 'No Title'}\`;
        const dataTable = \`${(this.tableContainer.nativeElement as HTMLDivElement).innerHTML}\`;
        </script>`);
      printWindow.document.write(documentContent);

      dialog.closeAll();
    } catch (e) {
      this._msgDialog.open({
        title: "Error",
        message: `An error occurred: ${JSON.stringify(e)}`,
        messageType: "text-red-400"
      }, true);

    }
  }

}

