<form [formGroup]="fgTR" (submit)="onSubmit()" class="card md:min-w-[500px]">
  <div class="card-header">
    For Reimbursement
  </div>
  <div class="card-body">
    <div class="overflow-auto max-h-[250px] p-4 border shadow-sm">
      <div class="grid grid-cols-12 gap-4">

        <div class="col-span-12">
          <div class="mb-4">
            <span class="form-label">Purpose <span class="text-red-400">*</span></span>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 sm:col-span-6 md:col-span-4">
                <mat-select formControlName="Purpose1" class="form-control">
                  <mat-option value="Travel">Travel</mat-option>
                  <mat-option value="Non-Travel Related">Non-Travel Related</mat-option>
                </mat-select>
              </div>
              <div class="col-span-12 sm:col-span-6 md:col-span-4">
                <mat-select formControlName="Purpose2" class="form-control">
                  <mat-option value="Vehicle Repairs">Vehicle Repairs</mat-option>
                  <mat-option value="Others">Others</mat-option>
                </mat-select>
              </div>
              <div class="col-span-12 md:col-span-4">
                <input *ngIf="isOtherFcPurpose2$|async" formControlName="Purpose3" type="text"
                  placeholder="Please Specify" class="form-control">
              </div>
            </div>
            <div *ngIf="getPuposeIsInvalid" class="text-red-400 text-sm">
              * Purpose is required.
            </div>
          </div>
        </div>

        <div class="col-span-12 md:col-span-8">
          <span class="form-label">Product <span class="text-red-400">*</span></span>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">
              <mat-select formControlName="Product1" class="form-control">
                <mat-option value="Project">Project</mat-option>
                <mat-option value="Program">Program</mat-option>
              </mat-select>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <input formControlName="Product2" type="text" matInput [matAutocomplete]="auto_Product"
                placeholder="Specify Product" class="form-control">
              <mat-autocomplete #auto_Product="matAutocomplete">
                <mat-option value="Sample Product 1">Sample Product 1</mat-option>
                <mat-option value="Sample Product 2">Sample Product 2</mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <div *ngIf="fgTR.get('Product2')?.invalid && fgTR.get('Product2')?.touched" class="text-red-500">
            <div *ngIf="fgTR.get('Product2')?.errors?.['required']" class="text-sm">
              * Product is required.
            </div>
          </div>
        </div>

        <div class="col-span-12 md:col-span-4">
          <span class="form-label"> Covered Date <span class="text-red-400">*</span></span>
          <div class="form-group">
            <mat-date-range-input [rangePicker]="picker_CoveredDate" class="form-group-input">
              <input formControlName="CoveredDateFrom" matStartDate placeholder="Select Date">
              <input formControlName="CoveredDateTo" matEndDate>
            </mat-date-range-input>
            <div class="form-group-label">
              <button (click)="picker_CoveredDate.open()" type="button" class="outline-none">
                <i class="fal fa-calendar"></i>
              </button>
              <mat-date-range-picker #picker_CoveredDate></mat-date-range-picker>
            </div>
          </div>
          <div *ngIf="getCoveredDateInvalid" class="text-red-500">
            <div *ngIf="fgTR.get('CoveredDateFrom')?.errors?.['required']" class="text-sm">
              * Covered Date From is required.
            </div>
            <div *ngIf="fgTR.get('CoveredDateTo')?.errors?.['required']" class="text-sm">
              * Covered Date To is required.
            </div>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6">
          <span class="form-label"> Transaction Date <span class="text-red-400">*</span></span>
          <div class="form-group">
            <input formControlName="TransactionDate" matInput [matDatepicker]="picker_TransactionDate"
              class="form-group-input">
            <div class="from-group-label">
              <button (click)="picker_TransactionDate.open()" type="button" class="outline-none">
                <i class="fal fa-calendar"></i>
              </button>
              <mat-datepicker #picker_TransactionDate></mat-datepicker>
            </div>
          </div>
          <div *ngIf="fgTR.get('TransactionDate')?.invalid && fgTR.get('TransactionDate')?.touched"
            class="text-red-500">
            <div *ngIf="fgTR.get('TransactionDate')?.errors?.['required']" class="text-sm">
              * Transaction Date is required.
            </div>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6">
          <span class="form-label"> Due Date <span class="text-red-400">*</span></span>
          <div class="form-group">
            <input formControlName="DueDate" matInput [matDatepicker]="picker_DueDate"
              [min]="Helper.date.addDay(fgTR.get('TransactionDate')?.value, 30)" class="form-group-input">
            <div class="from-group-label">
              <button (click)="picker_DueDate.open()" type="button" class="outline-none">
                <i class="fal fa-calendar"></i>
              </button>
              <mat-datepicker #picker_DueDate></mat-datepicker>
            </div>
          </div>
          <div *ngIf="fgTR.get('DueDate')?.invalid && fgTR.get('DueDate')?.touched" class="text-red-500">
            <div *ngIf="fgTR.get('DueDate')?.errors?.['required']" class="text-sm">
              * Due Date is required.
            </div>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6">
          <span class="form-label"> Reimbursement Amount <span class="text-red-400">*</span></span>
          <div class="form-group">
            <div class="form-group-label">₱</div>
            <input formControlName="ReimbursementAmount" type="text" (keypress)="Helper.input.positiveNumeric($event)"
              class="form-group-input">
          </div>
          <div *ngIf="fgTR.get('ReimbursementAmount')?.invalid && fgTR.get('ReimbursementAmount')?.touched"
            class="text-red-500">
            <div *ngIf="fgTR.get('ReimbursementAmount')?.errors?.['required']" class="text-sm">
              * Amount is required.
            </div>
            <div *ngIf="fgTR.get('ReimbursementAmount')?.errors?.['max']" class="text-sm">
              * Invalid amount. Max is {{data.CA_Request.Amount}}.
            </div>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6">
          <div class="mb-4">
            <span class="form-label">
              Proof
              <span class="text-red-400">* </span>
              <span class="text-muted">(PDF Format)</span>
            </span>
            <div class="form-group">
              <div class="form-group-label">
                <i class="fa-light fa-receipt"></i>
              </div>
              <label class="form-group-input {{fgTR.get('Proof')?.value?'':'text-zinc-400'}}"
                for="tab-tr-updload-proof">
                {{fgTR.get('Proof')?.value?.name ||'Upload Proof'}}
              </label>
              <input (input)="onUploadProof($event)" type="file" class="hidden" placeholder="Upload Proof" accept=".pdf"
                id="tab-tr-updload-proof">
            </div>
            <div *ngIf="fgTR.get('Proof')?.invalid && fgTR.get('Proof')?.touched" class="text-red-500">
              * Proof is required.
            </div>
          </div>
        </div>

        <div class="col-span-12">
          <div class="mb-4">
            <span class="form-label">Reimbursement Description <span class="text-red-400">*</span></span>
            <textarea formControlName="Description" class="form-control"></textarea>
            <div *ngIf="fgTR.get('Description')?.invalid && fgTR.get('Description')?.touched"
              class="text-red-500">
              * Description is required.
            </div>
          </div>
        </div>

        <div class="col-span-12">
          <div class="mb-4">
            <span class="form-label">Remarks </span>
            <textarea formControlName="Remarks" class="form-control"></textarea>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="alert alert-danger mb-4">
      <div class="font-semibold mb-2"><i class="fa-light fa-brake-warning"></i> Warning</div>
      Once submitted, values cannot be modified or altered. Please verify all fields before submitting.
    </div>
    <div *ngIf="loader.processing" class="alert alert-info mb-4">
      We are handling your request. Please wait.
    </div>
    <div *ngIf="prompt.message" class="{{prompt.type}}">{{prompt.message}}</div>

    <div class="form-submit mb-4">
      <button [disabled]="loader.processing" type="submit" class="custom-button custom-button-primary-filled">
        <i class="fal fa-plus-circle"></i> Save Transaction
      </button>
      <button (click)="onClose()" type="button" class="custom-button">Close</button>
    </div>
  </div>
</form>